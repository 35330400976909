@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }

/* 추가 css */

.imgBox {
  width: 140px;
  height: 224px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.imgBox > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}
/*================================================*/
/**/
.leftAside .top{
    display: flex;
    gap: 12px;
}

.leftAside .content{
    padding: 54px 40px 0 40px;
}


.profile{
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
}

.topProfile{
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
}

.bottomProfile{
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
}

.coin, .block{
    display: flex;
    justify-content: space-between;
}

.bottomProfile .right{
    text-align: right;
    text-decoration: underline;
}

.my .tit{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.my .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color:#8F8F99;
    margin-left: 4px;
}

.list_group{
    font-family: "Min Sans-Regular";
}
.list:first-of-type{
    border-top: 1px solid #2C2C34;
}
.list{
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2C2C34;
    padding: 12px 0 12px 20px ;

}

.list:last-of-type{
    margin-bottom: 42px;
}

.list_group .down{
    margin-left: auto;

}

.list_group .cate{
    padding-right: 4px;
}

.my.edit .list{
    color: #8F8F99;
    font-family: "Min Sans-Regular";
}

.leftAside, .container{
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
}

#wrap{
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto; 
    height: 100vh;
}

#container {
    padding: 0 134px;
    padding-top: 120px;
    overflow-y: auto;
}


.hidden_list {
    display: none;
    margin-top: 6px;
}


.hidden_list li{
    padding: 6px 0;
    color: #8F8F99;
}

.hidden_list li:hover{
    color: #fff;
}

.list_group .list{
    cursor: pointer;
}

.content{
    padding-top: 52px;
}

#textArea{
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
}
.content .tit{
    font-size: 15px;
    font-family: "Min Sans-Regular";
    color: #050409;
    margin-bottom: 4px;
    letter-spacing: -0.03em;

}

#textArea::placeholder {
    color: #828284;
}


#textArea {
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
}

.saveNextBtn{
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;

}

.leftAside{
    min-width: 320px;
    overflow-y: auto;
}


.small{
    display: none;
}

.big{display: block;}


.list_group .list:hover {
    color: #FF5F37;
}

/**select-tab**/

  .synop_select_tab > ul {
    list-style: none;
  }
  
  .synop_select_tab > li {
    list-style: none;
  }
  
  .synop_select_tab {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .my_picture_tab_nav {
    font-size: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
  }
  .my_picture_tab_nav li {
    display: inline-block;
    text-align: center;
    font-family: "Min Sans-bold";
    font-size: 24px;
    letter-spacing: -0.3px;
    color: #BDBDBD;
  }
  
  .my_picture_tab_nav li a.active {
    color: #050409;
  }
  .my_picture_tab_nav li a {
    margin-right: 18px;
  }
  .my_picture_tab_nav li a:hover{
    color: #050409;
    
  }
  .synop_select_tab_content {
    height: auto;
    border-top: none;
  }

/*container*/
.box{
    width: 100%;
    padding: 32px;
    border: 1px solid #EDEDED;
    border-radius: 20px;
    display: flex;
    align-items: flex-end;
    gap: 24px;
    margin-bottom: 20px;
}

.box:last-of-type{
    margin-bottom: 120px;
}

.content{
    display: flex;
    flex-direction: column;
}
.synop_select_tab_content .title{
    padding-bottom: 6px;
    font-size: 20px;
    font-family: "min sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    padding-bottom: 6px;
}


.price{
    color: #FF5F37;
    font-family: "min sans-bold";
    letter-spacing: -0.3px;
    padding-bottom: 16px;
}
.price .num{
    font-size: 20px;
}
.price .name{
    font-size: 14px;
    padding-right: 4px;
}
.show{
    display: flex;
    color: #050409;
    font-size: 14px;
    letter-spacing: -0.3px;
    font-family: "min sans-regular";
}

.show svg{
    vertical-align: middle;
    margin-right: 3px;
}
.view{
    margin-right: 12px;
}

.userSelect {
    display: flex;
    gap: 16px;
    padding-top: 20px;

}
.userSelect button {
    border:none;
    background-color: #F6F6F6;
    border-radius: 21px;
    padding: 11px 20px;
    font-family: "min sans-medium";
    letter-spacing: -0.3px;
    font-size: 15px;
    cursor: pointer;
}
.userSelect button:hover{
    background-color: #22222A;
    color: #fff;

}
#manageBtn{
    color: #22222A;
}

.declareBtn, .deleteBtn{
    color:#A8A8A9;
}

#manageBtn:hover, 
#downloadBtn:hover{
    color: #fff;
}
#synop02 .price{
    padding-bottom: 0;
}

/**modal**/

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 배경색 및 투명도 설정 */
    z-index: 1; /* 모달을 다른 요소 위에 표시 */
  }
  
  .modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 20px;
    max-width: 330px;
  }

  .modal p {
    font-size: 18px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    color: #22222A;
    margin-bottom: 24px;
  }

  
  
  /* 모달 버튼 스타일 */
  .modal button {
    padding: 12px 44px;
    margin: 5px;
    border: none;
    cursor: pointer;
    font-family: "Min Sans-Regular";
    font-size: 16px;
    border-radius: 8px;
  }
  
  /* 모달 닫기 버튼 스타일 */
  #cancelBtn {
    background-color: #f8f8f8;
    color: #22222A;
  }

  #reportBtn{
    background-color: #FF5F37;
    color: #fff;
  }


@media(max-width:1400px){
    #container{
        padding: 0 80px;
        padding-top: 120px;
    }

    #container, .leftAside{
        overflow-y: auto; 
    }
    .enter{
        display: none;
    }

    
.cut, .start{
    padding: 38px 20px;
}
}

@media(max-width:568px){

    #wrap{
        display: block;
    }
    #container{
        padding: 0 22px;
        padding-top: 70px;
    }
    .leftAside{
        display: none;
    }

    .userSelect{
        flex-direction: column;
    }

    .content{
        padding-top: 0;
    }
    .content .title{
        font-size: 16px;
        font-family: "min sans-bold";
        text-overflow: ellipsis;

    }

    .userSelect button{
        padding: 11px 16px;
        max-width: 120px;
    }

    }



