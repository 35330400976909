@font-face {
  font-family: "Min Sans-bold";
  src: url("../css/font/MinSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Min Sans-Medium";
  src: url("../css/font/MinSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Min Sans-Regular";
  src: url("../css/font/MinSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Min Sans-Light";
  src: url("../css/font/MinSans-Light.ttf") format("truetype");
}

/*23.11.17 추가 css 시작*/


.selectbtn {
  border: 1px solid #bdbdbd;
  background-color: #fff;
  padding: 18px 22px;
  color: #828284;
  font-family: "Min Sans-regular";
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 100%;
}

.selectbtn:hover {
  background-color: #ff562c;
  color: #fff;
  border: none;
}

.overlap input[type="number"] {
  width: 80%;
}

.passbook {
  padding: 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
}

.passbook::placeholder {
  color: #828284;
}

.passbook:focus {
  border: 1px solid #555;
}

.custom_file_upload {
  display: inline-block;
  padding: 18px 15px;
  cursor: pointer;
  background: #828284;
  border-right: none;
  color: #fff;
}


.custom_file_upload:hover {
  background: #5f5f61;
}

#file-preview img {
  width: auto;
  height: 200px;
}


.passbookselect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 18px 22px;
  color: #828284;
  font-family: "Min Sans-regular";
  border: 1px solid #bdbdbd;
  background-color: white;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><polygon points="5,8 10,13 15,8" fill="%23000"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

.passbookselect:hover {
  cursor: pointer;
}

.dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.dropdown_selected {
  padding: 18px 22px;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  font-family: "Min Sans-regular";
  color: #828284;
  align-items: center;
}

.dropdown_content {
  position: absolute;
  width: 100%;
  border: 1px solid #bdbddb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: none;
  z-index: 1;
}

.dropdown_item {
  display: flex;
  padding: 18px;
  cursor: pointer;
  font-family: "Min Sans-regular";
  font-size: 16px;
  color: #828284;
  align-items: center;
}

.dropdown_item:last-of-type {
  border-bottom: none;
}

.dropdown_item:hover {
  background-color: #f6f6f6;
}

.dropdown_flex {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.dropdown_item img {
  width: 25px;
  margin-right: 4px;
}

.dropdown_selected img {
  width: 25px;
  margin-right: 4px;
}
/*23.11.17 추가 css 끝*/
