@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


/**/

.logo {
  height: 24px;
}

.logo > img {
  height: 100%;
}

.leftAside .top {
  display: flex;
  gap: 12px;
}

.leftAside .content {
  padding: 54px 40px 0 40px;
}

.profile {
  border: 1px solid #464651;
  border-radius: 12px;
  padding: 20px;
  margin-top: 46px;
  margin-bottom: 42px;
}

.topProfile {
  color: #fff;
  display: flex;
  font-size: 18px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  padding-bottom: 24px;
  gap: 4px;
}

.bottomProfile {
  color: #ff5f37;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.03em;
  gap: 8px;
}

.coin,
.block {
  display: flex;
  justify-content: space-between;
}

.bottomProfile .right {
  text-align: right;
  text-decoration: underline;
}

.my .tit {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.my .tit p {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  color: #8f8f99;
  margin-left: 4px;
}

.list_group {
  font-family: "Min Sans-Regular";
}
.list:first-of-type {
  border-top: 1px solid #2c2c34;
}
.list {
  display: flex;
  color: #fff;
  border-bottom: 1px solid #2c2c34;
  padding: 12px 0 12px 20px;
}

.list:last-of-type {
  margin-bottom: 42px;
}

.list_group .down {
  margin-left: auto;
}

.list_group .cate {
  padding-right: 4px;
}

.my.edit .list {
  color: #8f8f99;
  font-family: "Min Sans-Regular";
}

#wrap {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  height: 100vh;
}

.leftAside,
.container,
.rightAside {
  background-color: #22222a;
  height: 100%;
  overflow-y: auto;
}

/**container**/

.container {
  background-color: #fff;
}

#container {
  overflow: scroll;
  padding: 0 140px;
}

.synop_tab_group {
  width: 100%;
}

.synop_tab {
  margin-top: 108px;
  display: flex;
  justify-content: center;
}

.synop_tab_btn > a {
  padding: 12px 0px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #a8a8a9;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_tab_btn_nline {
  width: 100%;
  height: 4px;
  background-color: #ededed;
  margin-top: 12px;
}

.synop_tab_btn_line {
  width: 100%;
  height: 4px;
  background-color: #ff5f37;
  margin-top: 12px;
}

.synop_tab_btn_u {
  text-align: center;
}

.synop_tab_btn {
  text-align: center;
}

.synop_tab_btn_u > a {
  padding: 12px 0px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #ff5f37;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_select {
  margin-top: 76px;
}

.synop_select_title {
  font-family: "Min Sans-bold";
  font-size: 32px;
  letter-spacing: -0.3px;
}

.synop_select_tab > ul {
  list-style: none;
}

.synop_select_tab > li {
  list-style: none;
}

.synop_select_tab {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.synop_select_tab_nav {
  font-size: 0;
  width: 100%;
  border: none;
  margin-bottom: 20px;
}
.synop_select_tab_nav li {
  display: inline-block;
  text-align: center;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #22222a;
}

.synop_select_tab_nav li a.active {
  border-bottom: 1px solid #fff;
}
.synop_select_tab_nav li a {
  position: relative;
  display: block;
  color: #22222a;
  padding: 8px 20px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid #22222a;
}
.synop_select_tab_nav li a:hover,
.synop_select_tab_nav li a.active {
  background: #040A20;
  color: #fff;
  border: 1px solid #040A20;
}
.synop_select_tab_content {
  height: auto;
  border-top: none;
}

/**synopsis content**/
.column {
  column-count: 2;
  margin-bottom: 12px;
}

.rank_synop {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
}

.rank_title_d > h1 {
  color: #fff;
}

.rank_title_d > h2 {
  color: #fff;
}

.synop_img {
  position: relative;
}

.synop_overlay {
  position: absolute;
  left: 20px;
  right: 0;
  bottom: 20px;
  color: #fff;
  padding: 10px;
}

.synop_illu {
  border-radius: 20px;
  width: 100%;
}

.gradient_overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  border-radius: 20px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.8)
  );
  pointer-events: none;
}

.synop_title {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.3px;
  opacity: 0.9;
  margin-bottom: 6px;
}

.synop_sub {
  font-size: 15px;
  font-family: "Min Sans-Light";
  letter-spacing: -0.3px;
  opacity: 0.7;
  width: 356px;
}

/**페이지네이션**/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.pagination a {
  text-decoration: none;
  margin: 5px;
  padding: 5px 10px;
  color: #333;
  font-family: "Min Sans-Light";
  font-size: 14px;
}

.pagination a:hover {
  background-color: #fff;
  color: #ff5f37;
}

.current_page {
  color: #ff5f37 !important;
}

@media (max-width: 1400px) {
  #wrap {
    display: grid;
    grid-template-columns: 1.5fr 4fr;
    grid-template-rows: auto;
    height: 100vh;
  }

  .column {
    column-count: 1;
  }

  .col {
    margin-bottom: 12px;
  }

  .synop_select_tab_nav li a {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .leftAside,
  .rightAside {
    display: none;
  }

  #wrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  #container {
    padding: 0 22px;
  }
  .synop_tab{
    margin-top: 70px;
  }
  .synop_select{
    margin-top: 60px;
  }
  .rank_synop{
    padding-bottom: 80px;
  }

  .synop_select_title{
    font-size: 24px;
  }
  .synop_select_tab_nav{
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 12px;
  }
  .synop_select_tab_nav::-webkit-scrollbar
  {
    height: 6px;
    background-color: #ededed;
  }
  .synop_select_tab_nav::-webkit-scrollbar-thumb{
    background-color: #d9d9d9;
    border-radius: 5px;
  }
  
}
