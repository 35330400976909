@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}

/**/

.logo {
  height: 24px;
}

.logo > img {
  height: 100%;
}

.leftAside .top {
  display: flex;
  gap: 12px;
}

.leftAside .content {
  padding: 54px 40px 0 40px;
}

.profile {
  border: 1px solid #464651;
  border-radius: 12px;
  padding: 20px;
  margin-top: 46px;
  margin-bottom: 42px;
}

.topProfile {
  color: #fff;
  display: flex;
  font-size: 18px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  padding-bottom: 24px;
  gap: 4px;
}

.bottomProfile {
  color: #ff5f37;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.03em;
  gap: 8px;
}

.coin,
.block {
  display: flex;
  justify-content: space-between;
}

.bottomProfile .right {
  text-align: right;
  text-decoration: underline;
}

.my .tit {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.my .tit p {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  color: #8f8f99;
  margin-left: 4px;
}

.list_group {
  font-family: "Min Sans-Regular";
}
.list:first-of-type {
  border-top: 1px solid #2c2c34;
}
.list {
  display: flex;
  color: #fff;
  border-bottom: 1px solid #2c2c34;
  padding: 12px 0 12px 20px;
}

.list:last-of-type {
  margin-bottom: 42px;
}

.list_group .down {
  margin-left: auto;
}

.list_group .cate {
  padding-right: 4px;
}

.my.edit .list {
  color: #8f8f99;
  font-family: "Min Sans-Regular";
}

.rightAside .content {
  padding: 120px 40px 40px 40px;
}

#wrap {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: auto;
  height: 100vh;
}

.leftAside,
.container,
.rightAside {
  background-color: #22222a;
  height: 100%;
  overflow-y: auto;
}

.rightAside .title {
  color: #8f8f99;
  font-family: "Min Sans-Light";
  font-size: 17px;
  letter-spacing: -0.3px;
  line-height: 1.5;
}

.listInfo .title {
  margin-bottom: 6px;
}
.hidden_list {
  display: none;
}
.hidden_list li {
  padding: 6px 0;
  color: #8f8f99;
}
.hidden_list li:hover {
  color: #fff;
}
.list_group .list {
  cursor: pointer;
}
.list_group .list:hover {
  color: #ff5f37;
}
.leftAside {
  min-width: 320px;
}
::-webkit-scrollbar {
  display: none;
}
.nav_menu {
  margin-top: 46px;
  display: none;
}
#xmark-icon {
  display: none;
}
.top {
  align-items: center;
}
.nav {
  cursor: pointer;
}

/**container**/

#container {
  padding: 0 140px;
  overflow: scroll;
  position: relative;
}

.novel_top {
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  padding: 54px 140px 0px 140px;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.novel_title {
  font-size: 32px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.3px;
  color: #050409;
  word-break: break-all;
}

.novel_info {
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.novel_no {
  font-size: 20px;
  font-family: "Min Sans-Light";
  letter-spacing: -0.3px;
  color: #050409;
}

.novel_count {
  font-size: 15px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  color: #828284;
}

.novel_control > button {
  border: 1px solid #050409;
  color: #050409;
  background-color: #fff;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 15px;
  font-family: "Min Sans-Medium";
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 10px;
}

.novel_control > button:hover {
  background-color: #f6f6f6;
}

.novel_bottom {
  height: 100%;
  overflow: auto;
  padding-top: 200px;
}

.novel_bottom_scene {
  top: 8%;
  display: flex;
  align-items: center;
}

.scene_no {
  background-color: #ff5f37;
  color: #fff;
  font-size: 17px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  padding: 6px 16px;
  min-width:9%;
  margin-right: 6px;
  border-radius: 18px;
}
.scene_no2 {
  background-color: #ff5f37;
  color: #fff;
  font-size: 17px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  padding: 6px 16px;
  min-width:9%;
  margin-right: 6px;
  border-radius: 18px;
  min-width: -moz-fit-content;
  min-width: -webkit-fill-available;
  background-color: #adadad;
  cursor: pointer;
  min-width: fit-content;
  min-width: -webkit-fit-content;

}

.scene_text {
  font-size: 20px;
  font-family: "Min Sans-Regular";
  color: #050409;
  letter-spacing: -0.3px;
  word-break: break-all;
}

.scene_detail {
  margin-top: 8px;
  width: 100%;
  height: auto;
}

.scene_detail > textarea {
  border: none;
  font-size: 18px;
  font-family: "Min Sans-Light";
  color: #22222a;
  letter-spacing: -0.3px;
  width: 100%;
  overflow-y: hidden;
  resize: none;
  line-height: 40px;
}

.scene_detail > textarea:focus {
  outline: none;
}

.scene_group {
  margin-bottom: 24px;
}

.scene_no_n {
  background-color: #dddddd;
  color: #9b9b9b;
  font-size: 17px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  padding: 6px 16px;
  width: 10%;
  margin-right: 6px;
  border-radius: 18px;
  min-width: 72px;
}

.scene_text_n {
  font-size: 20px;
  font-family: "Min Sans-Regular";
  color: #9b9b9d;
  letter-spacing: -0.3px;
  word-break: break-all;
}
/**right**/

.tit {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.tit p {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  color: #8f8f99;
  margin-left: 4px;
}

.scene_btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

#showLeftAside {
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  padding: 6px 16px;
  background-color: transparent;
  color: #828284;
  border: 1px solid #828284;
  border-radius: 18px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 40px;
  display: none;
}

#showLeftAside:hover {
  background-color: #2c2c34;
}

.next_scene {
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  padding: 6px 16px;
  background-color: #ff5f37;
  color: #fff;
  border-radius: 18px;
  cursor: pointer;
}

.next_scene:hover {
  background-color: #ff7957;
}

.re_scene {
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  padding: 6px 16px;
  background-color: transparent;
  color: #828284;
  border: 1px solid #828284;
  border-radius: 18px;
  cursor: pointer;
}

.re_scene:hover {
  background-color: #2c2c34;
}

.rere_scene {
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  padding: 6px 16px;
  background-color: transparent;
  color: #828284;
  border: 1px solid #828284;
  border-radius: 18px;
  cursor: pointer;
}

.rere_scene:hover {
  background-color: #2c2c34;
}

.scene_main {
  margin-top: 24px;
  display: inline-grid;
}

.scene_n {
  font-family: "Min Sans-Regular";
  font-size: 16px;
  color: #fff;
  opacity: 0.7;
  margin-right: 16px;
  word-break: break-all;
}

.scene_main_list {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  justify-content: space-between;
}

.scene_main_list > label {
  font-family: "Min Sans-Regular";
  font-size: 16px;
  color: #fff;
  opacity: 0.7;
  margin-right: 16px;
  word-break: break-all;
}

.scene_main_list input[type="radio"] {
  appearance: none;
  min-width: 18px;
  min-height: 18px;
  border: 1px solid #464651;
  border-radius: 50%;
  background-color: #22222a;
  outline: none;
  cursor: pointer;
  position: relative;
}

.scene_main_list input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.scene_main_list input[type="radio"]:checked {
  border-color: #ff5f37;
}

.scene_main_list input[type="radio"]:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ff5f37;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.edit_hr {
  border: 1px solid #2c2c34;
  margin: 32px 0px;
}

.scene_select_list {
  background-color: #fff;
  border-radius: 12px;
  height: auto;
  padding: 20px;
  margin-top: 22px;
  display: none;
}

.scene_select_list_title {
  font-size: 17px;
  font-family: "Min Sans-Medium";
  color: #050409;
  letter-spacing: -0.3px;
  word-break: break-all;
}

.scene_select_list_content {
  margin-top: 12px;
  font-size: 15px;
  font-family: "Min Sans-Regular";
  color: #828284;
  letter-spacing: -0.3px;
  line-height: 28px;
}

.scene_select_list_btn {
  margin-top: 20px;
  width: 100%;
  padding: 8px 0px;
  background-color: #ff5f37;
  color: #fff;
  font-size: 15px;
  font-family: "MinSans-Medium";
  text-align: center;
  border-radius: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  letter-spacing: -0.3px;
}

.scene_select_list_btn:hover {
  background-color: #ff7957;
}

#s1c {
  display: none;
}

#s2c {
  display: none;
}

#s3c {
  display: none;
}

#s4c {
  display: none;
}

#s5c {
  display: none;
}

.scene_select_list .scene_main_select {
  display: flex;
  font-family: "Min Sans-Regular";
  font-size: 16px;
  color: #828284;
  word-break: break-all;
  margin-top: 22px;
  align-items: center;
  justify-content: space-between;
}

.scene_main_select {
  display: flex;
  font-family: "Min Sans-Regular";
  font-size: 16px;
  color: #fff;
  word-break: break-all;
  margin-top: 22px;
  align-items: center;
  justify-content: space-between;
}

.scene_main_select_1 {
  display: flex;
  align-items: center;
}

.scene_main_select_1 button {
  margin-left: 6px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.scene_main_select_1 button:hover {
  opacity: 0.7;
}

#input-area {
  display: none;
  align-items: center;
  margin: 10px 0px;
  justify-content: space-between;
}

.scene_n_input {
  font-family: "Min Sans-Regular";
  font-size: 16px;
  color: #fff;
  word-break: break-all;
  align-items: center;
  background-color: #22222a;
  border: #bcbcbf 1px solid;
  border-radius: 12px;
  min-width: 262px;
  height: 48px;
  padding: 12px 12px;
  word-wrap: break-word;
}

.scene_n_input:focus {
  outline: none;
}

.scene_n_toggle:checked {
  border-color: #ff5f37;
}

.scene_n_toggle {
  appearance: none;
  min-width: 18px;
  min-height: 18px;
  border: 1px solid #464651;
  border-radius: 50%;
  background-color: #22222a;
  outline: none;
  cursor: pointer;
  position: relative;
}

.scene_n_toggle:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ff5f37;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

/**modal**/
.swal2_title {
  font-size: 24px !important;
  font-family: "Min Sans-bold";
  color: #050409 !important;
}

.swal2_html_container {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Min Sans-Regular";
  color: #828284;
}

.swal2_popup {
  padding: 2.25rem !important;
}

.swal2_confirm {
  font-size: 16px;
  font-family: "Min Sans-Regular";
  color: #828284;
}

.swal2_cancel {
  font-size: 16px;
  font-family: "Min Sans-Regular";
  color: #828284;
}
.swal2_footer {
  font-family: "Min Sans-Regular";
}

@media (max-width: 1570px) {
  .scene_btn {
    display: block;
  }

  .next_scene {
    width: -moz-fit-content;
    width: fit-content;
  }

  .re_scene {
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 8px;
  }
}

@media (max-width: 1400px) {
  #container {
    padding: 0px 80px;
  }
  .leftAside {
    display: none;
    position: fixed;
    z-index: 2;
  }

  #showLeftAside {
    display: block;
  }

  #wrap {
    display: grid;
    grid-template-columns: 3fr 1.5fr;
    grid-template-rows: auto;
    height: 100vh;
  }

  .scene_btn {
    display: flex;
  }

  .re_scene {
    margin-top: 0px;
  }

  .novel_top {
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    padding: 54px 76px 0px 76px;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 968px) {
  .scene_btn {
    display: block;
  }

  .re_scene {
    margin-top: 8px;
  }

  .novel_info {
    display: block;
  }

  .novel_control > button {
    font-size: 13px;
    padding: 8px 8px;
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .leftAside,
  .rightAside {
    display: none;
  }

  #wrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  #container {
    padding: 0 22px;
  }
}
