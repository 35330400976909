/* 에러페이지 */
.ERROR {
  width: 690px;
  margin: 200px auto 0;
}
.ERROR h1 {
  color: #222;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
}
.ERROR .box {
  margin-top: 35px;
  padding: 30px;
  border: 1px solid #dde2e5;
  border-radius: 25px;
}
.ERROR .box p {
  display: flex;
  position: relative;
  min-height: 128px;
  padding-left: 168px;
  color: #666;
  font-size: 24px;
  font-weight: 400;
  align-items: center;
}
.ERROR .box p::before {
  content: '';
  display: block;
  position: absolute;
  left: 10px;
  top: 0px;
  width: 128px;
  height: 128px;
  background: url(css/images/ico_error.png) no-repeat;
}
.ERROR .box .btn_area {
  margin-top: 20px;
  text-align: center;
}
