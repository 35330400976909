.custom_img {
    object-fit: cover;
}
.ql-video {
    object-fit: cover;
    width: 100%;
    height: 700px;
}

@media (max-width: 1400px) {
    .custom_img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

@media (min-width: 1000px) and (max-width: 1400px) {
    .ql-video {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }
}
@media (min-width: 780px) and (max-width: 1000px) {
    .ql-video {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
}
@media (min-width: 500px) and (max-width: 780px) {
    .ql-video {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}
@media (min-width: 250px) and (max-width: 500px) {
    .ql-video {
        width: 100%;
        height: 120px;
        object-fit: cover;
    }
}
@media (min-width: 150px) and (max-width: 250px) {
    .ql-video {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }
}
@media (max-width: 150px) {
    .ql-video {
        width: 100%;
        height: 50px;
        object-fit: cover;
    }
}