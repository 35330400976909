@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


/**/

.logo {
  height: 24px;
}

.logo > img {
  height: 100%;
}

.leftAside .top {
  display: flex;
  gap: 12px;
}

.leftAside .content {
  padding: 54px 40px 0 40px;
}


.profile {
  border: 1px solid #464651;
  border-radius: 12px;
  padding: 20px;
  margin-top: 46px;
  margin-bottom: 42px;
}

.topProfile {
  color: #fff;
  display: flex;
  font-size: 18px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  padding-bottom: 24px;
  gap: 4px;
}

.bottomProfile {
  color: #ff5f37;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.03em;
  gap: 8px;
}

.coin,
.block {
  display: flex;
  justify-content: space-between;
}

.bottomProfile .right {
  text-align: right;
  text-decoration: underline;
}

.my .tit {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.my .tit p {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  color: #8f8f99;
  margin-left: 4px;
}

.list_group {
  font-family: "Min Sans-Regular";
}
.list:first-of-type {
  border-top: 1px solid #2c2c34;
}
.list {
  display: flex;
  color: #fff;
  border-bottom: 1px solid #2c2c34;
  padding: 12px 0 12px 20px;
}

.list:last-of-type {
  margin-bottom: 42px;
}

.list_group .down {
  margin-left: auto;
}

.list_group .cate {
  padding-right: 4px;
}

.my.edit .list {
  color: #8f8f99;
  font-family: "Min Sans-Regular";
}

#wrap {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  height: 100vh;
}

.leftAside,
.container,
.rightAside {
  background-color: #22222a;
  height: 100%;
  overflow-y: auto;
}

/**container**/

.container {
  background-color: #fff;
}

#container {
  overflow: scroll;
  padding: 0 140px;
}

.synop_select_sub {
  font-family: "Min Sans-Regular";
  font-size: 18px;
  color: #050409;
  letter-spacing: -0.3px;
}

.novel_name {
  margin-top: 50px;
}

.synop_select_sub_span {
  display: flex;
  align-items: baseline;
}

.synop_select_sub_span > span {
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #828284;
  letter-spacing: -0.3px;
  margin-left: 4px;
}

.novel_name_input {
  width: 100%;
  border: 1px solid #bdbdbd;
  font-size: 24px;
  font-family: "Min Sans-Light";
  color: #050409;
  padding: 22px 24px;
  letter-spacing: -0.3px;
  margin-top: 12px;
}

.novel_int {
  margin-top: 50px;
}

.novel_int_input {
  width: 100%;
  border: 1px solid #bdbdbd;
  font-size: 18px;
  font-family: "Min Sans-Light";
  color: #050409;
  padding: 22px 24px;
  letter-spacing: -0.3px;
  margin-top: 12px;
}

.illu_btn {
  margin-top: 12px;
}

.illu_btn > button {
  border-radius: 20px;
  padding: 8px 20px;
  font-family: "Min Sans-Medium";
  font-size: 15px;
  color: #828284;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  margin-right: 6px;
  cursor: pointer;
}

.illu_btn button:hover {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.illu_btn button.active {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.novel_illu {
  margin-top: 50px;
}

.imgupload {
  width: 140px;
}

.d_flex {
  display: flex;
}

.imgupload_int {
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #828284;
  letter-spacing: -0.3px;
  margin-top: 10px;
  line-height: 24px;
}

#image-preview {
  max-width: 100%;
  max-height: 200px;
  margin-top: 20px;
  display: none;
}

.illu_me_list > .col {
  margin-right: 3px;
}

.illu_me_list > .col > img {
  height: 180px;
}

.col {
  display: inline-grid;
}

.illu_me_radio {
  margin-top: 8px !important;
  width: 16px;
  height: 16px;
  margin: 0 auto;
}

.sub_title {
  font-family: "Min Sans-Regular";
  font-size: 14px;
  color: #828284;
  margin-bottom: 8px;
}

/**페이지네이션**/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination a {
  text-decoration: none;
  margin: 5px;
  padding: 5px 10px;
  color: #333;
  font-family: "Min Sans-Light";
  font-size: 14px;
}

.pagination a:hover {
  background-color: #fff;
  color: #ff5f37;
}

.current_page {
  color: #ff5f37 !important;
}

.edit_next {
  width: 100%;
  background-color: #22222a;
  color: #fff;
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  margin: 50px 0px 120px 0px;
  height: 70px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
}

.edit_next:hover {
  background-color: #303039;
}

/**edit-1**/
.synop_tab_group {
  width: 100%;
}

.synop_tab {
  margin-top: 108px;
  display: flex;
  justify-content: center;
}

.synop_tab_btn > a {
  padding: 12px 0px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #a8a8a9;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_tab_btn_nline {
  width: 100%;
  height: 4px;
  background-color: #ededed;
  margin-top: 12px;
}

.synop_tab_btn_line {
  width: 100%;
  height: 4px;
  background-color: #ff5f37;
  margin-top: 12px;
}

.synop_tab_btn_u {
  text-align: center;
}

.synop_tab_btn {
  text-align: center;
}

.synop_tab_btn_u > a {
  padding: 12px 0px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #ff5f37;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_select {
  margin-top: 76px;
}

.synop_select_title {
  font-family: "Min Sans-bold";
  font-size: 32px;
  letter-spacing: -0.3px;
}

.category_btn {
  margin-top: 12px;
}

.category_btn > button {
  border-radius: 20px;
  padding: 8px 20px;
  font-family: "Min Sans-Medium";
  font-size: 15px;
  color: #828284;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  margin-right: 6px;
  cursor: pointer;
}

.category_btn button:hover {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.category_btn button.active {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.day_btn1 {
  margin-top: 12px;
}

.day_btn1 > button {
  border-radius: 20px;
  padding: 8px 20px;
  font-family: "Min Sans-Medium";
  font-size: 15px;
  color: #828284;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  margin-right: 6px;
  cursor: pointer;
}

.day_btn1 button:hover {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.day_btn1 button.active {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.day_btn2 {
  margin-top: 12px;
}

.day_btn2 > button {
  border-radius: 20px;
  padding: 8px 20px;
  font-family: "Min Sans-Medium";
  font-size: 15px;
  color: #828284;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  margin-right: 6px;
  cursor: pointer;
}

.day_btn2 button:hover {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.day_btn2 button.active {
  border: 1px solid #22222A;
  background-color: #22222A;
  color: #fff;
}

.synop_select_tab > ul {
  list-style: none;
}

.synop_select_tab > li {
  list-style: none;
}

.synop_select_tab {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.synop_select_tab_nav {
  font-size: 0;
  width: 100%;
  border: none;
  margin-bottom: 20px;
}
.synop_select_tab_nav li {
  display: inline-block;
  text-align: center;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #22222a;
}

.synop_select_tab_nav li a.active {
  border-bottom: 1px solid #fff;
}
.synop_select_tab_nav li a {
  position: relative;
  display: block;
  color: #22222a;
  padding: 8px 20px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid #22222a;
}
.synop_select_tab_nav li a:hover,
.synop_select_tab_nav li a.active {
  background: #22222A;
  color: #fff;
  border: 1px solid #22222A;
}
.synop_select_tab_content {
  height: auto;
  border-top: none;
}
@media (max-width: 1400px) {
  #wrap {
    display: grid;
    grid-template-columns: 1.5fr 4fr;
    grid-template-rows: auto;
    height: 100vh;
  }

  .illu_me_list > .col > img {
    height: 220px;
    margin-top: 16px;
  }

  .synop_select_sub {
    font-size: 16px;
  }
}

@media (min-width: 968px) and (max-width: 1280px) {
  .illu_me_list > .col > img {
    height: 152px;
    margin-top: 16px;
  }

  .synop_select_sub {
    font-size: 16px;
  }

  .day_btn2 > button {
    margin-top: 12px;
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .leftAside,
  .rightAside {
    display: none;
  }

  #wrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  #container {
    padding: 0 22px;
  }

  .synop_tab{
    margin-top: 70px;
  }

  .rank_synop{
    padding-bottom: 80px;
  }
  .synop_select_title{
    font-size: 24px;
    color: #050409;
  }

  .synop_select{
    margin-top: 60px;
  }
  

  .category_btn > button{
    font-size: 13px;
    padding: 8px 12px;
    margin-right: 4px;
  }

  .novel_name,
  .novel_int,
  .novel_illu{
    margin-top: 42px;
  }

  .synop_select_sub_span{
    flex-direction: column;
    gap: 4px;
  }

  .novel_name_input{
    font-size: 18px;
    padding: 22px 16px;
  }

  .synop_select_tab_nav li a{
    font-size: 13px;
    padding: 8px 12px;
    color: #828284;
    border: 1px solid #bdbdbd;
  }

  .day_btn1 > button{
    font-size: 13px;
    padding: 8px 12px;
    margin-right:4px
  }

  .day_btn2 > button{
    font-size: 13px;
    padding: 8px 12px;
    margin-right:4px;
    margin-bottom:4px
  }

  .day_btn2{
    margin-top: 20px;
  }

  .edit_next{
    margin-top: 40px;
    margin-bottom: 80px;
  }

  #image-preview {
    width: 100px; /* 이미지 크기 조정 */
    height: 100px;
    /* 원하는 스타일 지정 */
  }
}
