@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
}

body {
  overflow-x: hidden;
  -webkit-user-select: none;
  user-select: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
h1 {
  font-size: 46px;
  font-family: "Min Sans-bold";
  color: #050409;
  letter-spacing: -0.3px;
}

h2 {
  font-size: 20px;
  font-family: "Min Sans-bold";
  color: #050409;
  letter-spacing: -0.3px;
}

li {
  list-style: none;
}

a {
  color: inherit;
}

.custom_class_to_image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.col {
  padding: 0px;
}
/**nav**/

.nav_logo {
  height: 24px;
}

.custom_navbar {
  background: #fff !important;
  height: 120px;
}

.navbar_brand {
  margin-left: 16px;
}

.custom_navbar .navbar_toggler {
  border-color: transparent;
}
.custom_navbar .navbar_toggler:active,
.custom_navbar .navbar_toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.custom_navbar .custom_navbar_nav li a {
  color: #050409 !important;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
}

.custom_navbar .custom_navbar_nav li a:before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 8px;
  right: 8px;
  background: #ff562c;
  height: 2px;
  opacity: 1;
  visibility: visible;
  width: 0;
  -webkit-transition: 0.15s all ease-out;
  -o-transition: 0.15s all ease-out;
  transition: 0.15s all ease-out;
}

.custom_navbar .custom_navbar_nav li a:hover {
  opacity: 1;
}
.custom_navbar .custom_navbar_nav li a:hover:before {
  width: calc(100% - 16px);
}
.custom_navbar .custom_navbar_nav li.active a {
  opacity: 1;
}
.custom_navbar .custom_navbar_nav li.active a:before {
  width: calc(100% - 16px);
}
.custom_navbar .custom_navbar_cta {
  margin-left: 0 !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.custom_navbar .custom_navbar_cta {
  margin-left: 20px !important;
}

.custom_navbar .custom_navbar_cta li {
  margin-left: 0px;
  margin-right: 0px;
}

.custom_navbar .custom_navbar_cta li:first-of-type {
  margin-right: 20px;
}

/**slide**/

.carousel {
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel_inner {
  width: 100%;
  display: flex;
  transition: transform 0.8s ease-in-out;
}

.carousel_inner .carousel_item {
  padding: 0px 10px;
}

.carousel_inner .carousel_item > img {
  border-radius: 20px;
}

.carousel_indicators {
  right: 10px;
}

.carousel_indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.carousel_control_next,
.carousel_control_prev {
  padding: 0px 0px;
  width: 80px;
}

/**container**/
.container {
  padding: 0;
}

.rank_title h1 {
  margin-bottom: 22px;
}

.notice {
  padding: 0;
  margin-bottom: 100px;
}
.notice li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notice li {
  border-bottom: 1px solid #ededed;
}

.notice .title {
  padding: 28px 0;
  color: #050409;
  font-family: "Min Sans-bold";
  font-size: 28px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.notice .date {
  color: #050409;
  font-family: "Min Sans-bold";
  font-size: 18px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.rank_title {
  padding-top: 60px;
}
.synop_select_tab_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new {
  display: none; /* 기본적으로 숨깁니다. */
}

/* #synop01이 활성화됐을 때 .new를 보이게 합니다. */
#synop01.active + .new {
  display: block;
}

#newWrite {
  color: #fff;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid #ff5f37;
  background-color: #ff5f37;
  font-family: "min sans-regular";
  letter-spacing: -0.3px;
  white-space: nowrap;
}

.question {
  padding: 24px 0;
  color: #050409;
  font-family: "Min Sans-regular";
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
  display: inline-block;
}

.finish {
  color: #fff;
  background-color: #ff562c;
  border: 1px solid #ff5f37;
  letter-spacing: -0.3px;
  font-size: 13px;
  padding: 4px 12px;
  border-radius: 19px;
  display: inline-block;
}

.finish.wait {
  color: #9b9b9d;
  background-color: #fff;
  border: 1px solid #9b9b9d;
  padding: 4px 16px;
  white-space: nowrap;
}

#synop02 .notice li:first-of-type {
  border-top: 1px solid #ededed;
}

/**free**/
.rank_free {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1272x;
  margin: 0 auto;
}

.container_l {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

/*synop-tab*/

.synop_select_tab_nav {
  display: flex;
  padding: 0;
  margin-bottom: 24px;
}
.synop_select_tab_nav li a:hover,
.synop_select_tab_nav li a.active {
  background: #040a20;
  color: #fff;
  border: 1px solid #040a20;
}

.synop_select_tab_content {
  height: auto;
  border-top: none;
  margin-bottom: 120px;
}

.synop_select_tab_nav li {
  display: inline-block;
  text-align: center;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #22222a;
}
.synop_select_tab_nav li a {
  position: relative;
  display: block;
  color: #22222a;
  padding: 8px 20px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid #22222a;
  letter-spacing: -0.3px;
}

/**페이지네이션**/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.pagination a {
  text-decoration: none;
  margin: 5px;
  padding: 5px 10px;
  color: #333;
  font-family: "Min Sans-Light";
  font-size: 14px;
}

.pagination a:hover {
  background-color: #fff;
  color: #ff5f37;
}

.current_page {
  color: #ff5f37 !important;
}

/**footer**/
.footer {
  padding: 44px 0px;
  max-width: 1320px;
  margin: 0 auto;
}

.footer_img {
  height: 20px;
  width: auto;
  margin-bottom: 20px;
}

.footer_img > img {
  width: 100%;
}

.footer_text_1 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.footer_text_2 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  opacity: 0.5;
}

.footer_text_3 {
  margin-top: 20px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  font-size: 13px;
}

.footer_text_3 > a {
  color: #050409;
  text-decoration: none;
  margin-right: 12px;
}

/*추가css*/
.notice_detail {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
}

.notice_detail .title {
  padding: 28px 0;
  color: #050409;
  font-family: "Min Sans-bold";
  font-size: 28px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.notice_detail .date {
  color: #050409;
  font-family: "Min Sans-bold";
  font-size: 18px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.notice_hr {
  margin: 0px auto;
  border: #ededed 1px solid;
}

.notice_detail_img {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.notice_detail_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.notice_detail_content {
  margin-bottom: 240px;
}

.notice_detail_text {
  margin-top: 32px;
  font-family: "Min Sans-Light";
  letter-spacing: -0.3px;
  line-height: 36px;
  font-size: 20px;
  color: #050409;
}

.que_content {
  display: none;
  margin-bottom: 24px;
}

.que_content_q {
  color: #050409;
  font-family: "Min Sans-Bold";
  font-size: 18px;
  letter-spacing: -0.3px;
}

.que_content_a {
  color: #ff562c;
  font-family: "Min Sans-Bold";
  font-size: 18px;
  letter-spacing: -0.3px;
  margin-top: 12px;
}

.que_content_n {
  color: #050409;
  font-family: "Min Sans-Regular";
  font-size: 15px;
  letter-spacing: -0.3px;
}

.qna_reg {
  display: none;
  margin-bottom: 66px;
}

.qna_reg input {
  height: 52px;
  border: #bdbdbd 1px solid;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 8px 16px;
  font-family: "Min Sans-Regular";
  color: #050409;
  font-size: 16px;
}

.qna_reg input:focus {
  outline: none;
}

.qna_reg textarea {
  height: 250px;
  border: #bdbdbd 1px solid;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 8px 16px;
  font-family: "Min Sans-Regular";
  color: #050409;
  font-size: 16px;
  word-wrap: break-word;
}

.qna_reg textarea:focus {
  outline: none;
}

#newreg {
  color: #fff;
  padding: 8px 26px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid #ff5f37;
  background-color: #ff5f37;
  font-family: "min sans-regular";
  letter-spacing: -0.3px;
  white-space: nowrap;
  width: 300px;
  margin: 0 auto;
}

.service_use_content {
  margin-top: 12px;
  font-family: "Min Sans-Light";
  letter-spacing: -0.3px;
  line-height: 28px;
  font-size: 18px;
  color: #050409;
  margin-bottom: 240px;
}

@media (max-width: 1400px) {
  .custom_navbar .custom_navbar_nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 14px;
    letter-spacing: -0.5px;
    margin: 0px 10px;
  }

  .rank_synop {
    max-width: 1296px;
  }

  .rank_illu {
    max-width: 1296px;
  }

  .footer {
    max-width: 1296px;
  }

  .contaier {
    padding: 0 120px;
  }

  .notice_detail {
    margin-bottom: 32px;
  }
}

@media (min-width: 1400px) {
  .newWrite {
    display: block;
  }
}

@media (max-width: 560px) {
  /**nav**/
  .custom_navbar {
    background-color: #fff;
    height: 50px;
    padding: 0 18px;
    margin: 0 auto;
    margin-bottom: 12px;
  }

  .nav_logo {
    height: 18px;
  }

  .navbar_brand {
    margin-left: 0px;
  }

  .navbar_toggler {
    padding: 4px 0px;
  }

  .navbar > .container {
    padding: 0px;
  }

  .custom_navbar .custom_navbar_nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 12px 18px;
  }

  .navbar_collapse {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    top: 60px;
    right: 20px;
    width: 100%;
  }

  .nav_link {
    padding: 0px 22px;
  }

  .custom_navbar .custom_navbar_nav li a:before {
    margin: 10px 14px;
    bottom: -16px;
  }

  /**slide**/

  .rank_synop {
    max-width: 540px;
  }

  .rank_illu {
    max-width: 540px;
  }

  .footer {
    max-width: 540px;
  }

  .rank_title {
    padding-top: 120px;
  }

  .container_l {
    max-width: 540px;
  }
}
@media (max-width: 568px) {
  .notice li a {
    display: block;
  }

  .notice .title {
    padding: 18px 0px;
    font-size: 20px;
  }

  .notice .date {
    margin-bottom: 18px;
  }

  .synop_select_tab_nav li a {
    padding: 8px 12px;
  }

  .synop_select_tab_nav {
    margin-bottom: 0;
  }

  .rank_title {
    padding-top: 40px;
  }

  .rank_title h1 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .container {
    padding: 0 18px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .notice_detail .title {
    font-size: 20px;
    padding: 16px 0;
  }

  .notice_detail .date {
    display: none;
  }

  .synop_select_tab_nav {
    margin-bottom: 20px;
  }

  .synop_select_tab_nav li a {
    font-size: 13px;
    padding: 8px 12px;
    margin-right: 6px;
  }
  .synop_select_tab_content {
    margin-bottom: 80px;
  }

  .question {
    display: block;

    padding-top: 16px;
    padding-bottom: 10px;
  }

  .finish {
    margin-bottom: 16px;
  }
}
