@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


/*body*/

/**nav**/

.nav-logo {
  height: 24px;
}

.custom_navbar {
  background: #fff !important;
  height: 120px;
}

.navbar_brand {
  margin-left: 16px;
}

.custom_navbar .navbar_toggler {
  border-color: transparent;
}
.custom_navbar .navbar_toggler:active,
.custom_navbar .navbar_toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.custom_navbar .custom_navbar_nav li a {
  color: #050409 !important;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
}

.custom_navbar .custom_navbar_nav li a:before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 8px;
  right: 8px;
  background: #ff562c;
  height: 2px;
  opacity: 1;
  visibility: visible;
  width: 0;
  -webkit-transition: 0.15s all ease-out;
  -o-transition: 0.15s all ease-out;
  transition: 0.15s all ease-out;
}

.custom_navbar .custom_navbar_nav li a:hover {
  opacity: 1;
}
.custom_navbar .custom_navbar_nav li a:hover:before {
  width: calc(100% - 16px);
}
.custom_navbar .custom_navbar_nav li.active a {
  opacity: 1;
}
.custom_navbar .custom_navbar_nav li.active a:before {
  width: calc(100% - 16px);
}
.custom_navbar .custom_navbar_cta {
  margin-left: 0 !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.custom_navbar .custom_navbar_cta {
  margin-left: 20px !important;
}

.custom_navbar .custom_navbar_cta li {
  margin-left: 0px;
  margin-right: 0px;
}

.custom_navbar .custom_navbar_cta li:first-of-type {
  margin-right: 20px;
}

/**slide**/

.carousel {
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel_inner {
  width: 100%;
  display: flex;
  transition: transform 0.8s ease-in-out;
}

.carousel_inner .carousel_item {
  padding: 0px 10px;
}

.carousel_inner .carousel_item > img {
  border-radius: 20px;
}

.carousel_indicators {
  right: 10px;
}

.carousel_indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.carousel_control_next,
.carousel_control_prev {
  padding: 0px 0px;
  width: 80px;
}

/* cotent */

#container {
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 0px 324px;
  padding-top: 120px;
}

.title {
  text-align: center;
  margin-bottom: 82px;
}

.title h4 {
  font-family: "Min Sans-bold";
  font-size: 20px;
  letter-spacing: -0.3px;
  color: #050409;
  padding-bottom: 8px;
}
.title h2 {
  font-family: "Min Sans-bold";
  font-size: 46px;
  letter-spacing: -0.3px;
  color: #050409;
}

#serviceAgree {
  padding: 24px 32px;
  color: #828284;
  font-size: 16px;
  letter-spacing: -0.3px;
  font-family: "Min Sans-Regular";
  line-height: 1.8;
  width: 100%;
  height: 352px;
  outline: none;
  border: 1px solid #bdbdbd;
  text-align: left;
  cursor: default;
}



.saveNextBtn {
  color: #fff;
  background-color: #22222a;
  border-radius: 20px;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  margin-top: 82px;
  margin-bottom: 120px;
  width: 100%;
  padding: 24px;
  border: none;
  cursor: pointer;
}

.saveNextBtn:hover {
  background-color: #303039;
}


/**footer**/

.rank_free {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
}

.container_l {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

.footer {
  padding: 44px 0px;
  max-width: 1320px;
  margin: 0 auto;
}

.footer_img {
  height: 20px;
  width: auto;
  margin-bottom: 20px;
}

.footer_img > img {
  width: 100%;
}

.footer_text_1 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.footer_text_2 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  opacity: 0.5;
}

.footer_text_3 {
  margin-top: 20px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  font-size: 13px;
}

.footer_text_3 > a {
  color: #050409;
  text-decoration: none;
  margin-right: 12px;
}

.list_group .list:hover {
  color: #ff5f37;
}

/*탈퇴*/

.reOK_1 {
  text-align: center;
  font-family: "Min Sans-Medium";
  color: #050409;
  font-size: 20px;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
}

.reOK_2 {
  text-align: center;
  font-family: "Min Sans-Regular";
  color: #828284;
  font-size: 16px;
  letter-spacing: -0.3px;
}

@media (max-width: 1400px) {
  .custom_navbar .custom_navbar_nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 14px;
    letter-spacing: -0.5px;
    margin: 0px 10px;
  }

  .rank_synop {
    max-width: 1296px;
  }

  .rank_illu {
    max-width: 1296px;
  }

  .footer {
    max-width: 1296px;
  }

  #container {
    max-width: 100%;
    width: auto;
    height: auto;
    padding: 0px 120px;
    padding-top: 120px;
  }
  .saveNextBtn {
    margin-bottom: 120px;
  }
}

@media (max-width: 560px) {
  /**nav**/
  .custom_navbar {
    background-color: #fff;
    height: 50px;
    padding: 0 18px;
    margin: 0 auto;
    margin-bottom: 12px;
  }

  .nav_logo {
    height: 18px;
  }

  .navbar_brand {
    margin-left: 0px;
  }

  .navbar_toggler {
    padding: 4px 0px;
  }

  .navbar > .container {
    padding: 0px;
  }

  .custom_navbar .custom_navbar_nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 12px 18px;
  }

  .navbar_collapse {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    top: 60px;
    right: 20px;
    width: 100%;
  }

  .nav_link {
    padding: 0px 22px;
  }

  .custom_navbar .custom_navbar_nav li a:before {
    margin: 10px 14px;
    bottom: -16px;
  }

  /**slide**/

  .rank_synop {
    max-width: 540px;
  }

  .rank_illu {
    max-width: 540px;
  }

  .footer {
    max-width: 540px;
  }

  .rank_title {
    padding-top: 120px;
  }

  .container_l {
    max-width: 540px;
  }

  .container_d {
    max-width: 540px;
  }

  .logBox {
    padding-top: 48px;
  }
}

@media (max-width: 568px) {
  #container {
    padding: 0 22px;
  }


  .saveNextBtn {
    margin-top: 42px;
    margin-bottom: 80px;
  }

  .title {
    margin-bottom: 62px;
    margin-top: 70px;
  }
  .title h1 {
    font-size: 18px;
  }
  .title h2 {
    font-size: 36px;
  }
}
