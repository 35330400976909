@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


/**/

.logo {
  height: 24px;
}

.logo > img {
  height: 100%;
}

.leftAside .top {
  display: flex;
  gap: 12px;
}

.leftAside .content {
  padding: 54px 40px 0 40px;
}

.profile {
  border: 1px solid #464651;
  border-radius: 12px;
  padding: 20px;
  margin-top: 46px;
  margin-bottom: 42px;
}

.topProfile {
  color: #fff;
  display: flex;
  font-size: 18px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  padding-bottom: 24px;
  gap: 4px;
}

.bottomProfile {
  color: #ff5f37;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.03em;
  gap: 8px;
}

.coin,
.block {
  display: flex;
  justify-content: space-between;
}

.bottomProfile .right {
  text-align: right;
  text-decoration: underline;
}

.my .tit {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.my .tit p {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  color: #8f8f99;
  margin-left: 4px;
}

.list_group {
  font-family: "Min Sans-Regular";
}
.list:first-of-type {
  border-top: 1px solid #2c2c34;
}
.list {
  display: flex;
  color: #fff;
  border-bottom: 1px solid #2c2c34;
  padding: 12px 0 12px 20px;
}

.list:last-of-type {
  margin-bottom: 42px;
}

.list_group .down {
  margin-left: auto;
}

.list_group .cate {
  padding-right: 4px;
}

.my.edit .list {
  color: #8f8f99;
  font-family: "Min Sans-Regular";
}

#wrap {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  height: 100vh;
}

.leftAside,
.container,
.rightAside {
  background-color: #22222a;
  height: 100%;
  overflow-y: auto;
}

/**container**/

.container {
  background-color: #fff;
}

#container {
  overflow: scroll;
  padding: 0 140px;
}
/**edit-3**/
.character_box {
  height: auto;
  margin: 10px 0px 16px 0px;
  padding: 38px 42px;
  border: 1px solid #22222a;
}

.novel_name_input {
  width: 100%;
  border: 1px solid #bdbdbd;
  font-size: 16px;
  font-family: "Min Sans-Regular";
  color: #050409;
  padding: 16px 16px;
  letter-spacing: -0.3px;
  margin-top: 4px;
}



.character_add {
  padding: 10px 30px;
  border-radius: 20px;
  background-color: #ff5f37;
  color: #fff;
  font-size: 15px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
}

.text_end {
  text-align: end;
}

.character_add:hover {
  background-color: #ff7957;
}

.character_sub_title {
  font-family: "Min Sans-Regular";
  font-size: 14px;
  color: #828284;
  cursor: pointer;
  text-decoration: underline;
}

.character_title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.character {
  height: auto;
  margin-bottom: 50px;
}


.character_delete {
  padding: 10px 30px;
  border-radius: 20px;
  background-color: #f6f6f6;
  color: #a8a8a9;
  font-size: 15px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.character_delete:hover {
  background-color: #e8e8e8;
}

/**edit-2**/


.synop_select_sub {
  font-family: "Min Sans-Regular";
  font-size: 18px;
  color: #050409;
  letter-spacing: -0.3px;
}

.sub_title {
  font-family: "Min Sans-Regular";
  font-size: 14px;
  color: #828284;
}

.edit_next {
  width: 100%;
  background-color: #22222a;
  color: #fff;
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  margin: 50px 0px 120px 0px;
  height: 70px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
}

.edit_next:hover {
  background-color: #303039;
}
.column {
  column-count: 4;
  margin: 12px 0px 12px 0px;
}
/**edit-1**/
.synop_tab_group {
  width: 100%;
}

.synop_tab {
  margin-top: 108px;
  display: flex;
  justify-content: center;
}

.synop_tab_btn > a {
  padding: 12px 0px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #a8a8a9;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_tab_btn_nline {
  width: 100%;
  height: 4px;
  background-color: #ededed;
  margin-top: 12px;
}

.synop_tab_btn_line {
  width: 100%;
  height: 4px;
  background-color: #ff5f37;
  margin-top: 12px;
}

.synop_tab_btn {
  text-align: center;
}

.synop_tab_btn_u {
  text-align: center;
}

.synop_tab_btn_u > a {
  padding: 12px 0px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #ff5f37;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_select {
  margin-top: 76px;
}

.synop_select_title {
  font-family: "Min Sans-bold";
  font-size: 32px;
  letter-spacing: -0.3px;
}

@media (max-width: 1400px) {
  #wrap {
    display: grid;
    grid-template-columns: 1.5fr 4fr;
    grid-template-rows: auto;
    height: 100vh;
  }

  .column {
    column-count: 2;
  }

  .sub_title {
    padding-top: 10px;
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .leftAside,
  .rightAside {
    display: none;
  }

  #wrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  #container {
    padding: 0 22px;
  }
  .synop_select{
    margin-top: 60px;
  }
  .synop_tab{
    margin-top: 70px;
  }

  .rank_synop{
    padding-bottom: 80px;
  }

  .synop_select_title{
    font-size: 24px;
  }

  .synop_select_sub{
    font-size: 16px;
  }

  .character_box{
    padding: 20px 18px;
  }

  .sub_title{
    font-size: 13px;
    color: #050409;
  }
  .sub_title{
    padding-top: 0;
  }

  .novel_name_input{
    font-size: 14px;
    padding: 12px 16px;
    margin-bottom: 16px;
  }

  .column{
    margin: 0;
    column-count: 1;
  }

  .character_add{
    padding: 10px 40px;
  }
  .edit_next{
    margin-top: 40px;
    margin-bottom: 80px;
  }
  .character{
    margin-bottom: 0;
  }
  .column .two{
    column-count: 2;
  }

}
