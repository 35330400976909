@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }


/**/
/**edit-1**/
.synop-tab-group {
    width: 100%;
  }
  
  .synop-tab {
    margin-top: 108px;
    display: flex;
    justify-content: center;
  }
  
  .synop-tab-btn > a {
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #a8a8a9;
    position: relative;
    letter-spacing: -0.3px;
  }
  
  .synop-tab-btn-nline {
    width: 100%;
    height: 4px;
    background-color: #ededed;
    margin-top: 12px;
  }
  
  .synop-tab-btn-line {
    width: 100%;
    height: 4px;
    background-color: #ff5f37;
    margin-top: 12px;
  }
  
  .synop-tab-btn-u {
    text-align: center;
  }
  
  .synop-tab-btn {
    text-align: center;
  }
  
  .synop-tab-btn-u > a {
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #ff5f37;
    position: relative;
    letter-spacing: -0.3px;
  }

.leftAside .top{
    display: flex;
    gap: 12px;
}

.leftAside .content{
    padding: 54px 40px 0 40px;
}



.profile{
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
}

.topProfile{
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
}

.bottomProfile{
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
}

.coin, .block{
    display: flex;
    justify-content: space-between;
}

.bottomProfile .right{
    text-align: right;
    text-decoration: underline;
}

.my .tit{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.my .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color:#8F8F99;
    margin-left: 4px;
}

.list_group{
    font-family: "Min Sans-Regular";
}
.list:first-of-type{
    border-top: 1px solid #2C2C34;
}
.list{
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2C2C34;
    padding: 12px 0 12px 20px ;

}

.list:last-of-type{
    margin-bottom: 42px;
}

.list_group .down{
    margin-left: auto;

}

.list_group .cate{
    padding-right: 4px;
}

.my.edit .list{
    color: #8F8F99;
    font-family: "Min Sans-Regular";
}

.leftAside, .container{
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
}

#wrap{
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto; 
    height: 100vh;
}

#container {
    padding: 0 208px;
    padding-top: 104px;
    padding-bottom: 80px;
    

}
#container h2{
    font-size: 32px;
    font-family: "Min Sans-Medium";
    padding-bottom: 28px;
}


.hidden-list {
    display: none;
    margin-top: 6px;
}


.hidden-list li{
    padding: 6px 0;
    color: #8F8F99;
}

.hidden-list li:hover{
    color: #fff;
}

.list_group .list{
    cursor: pointer;
}

.content{
    padding-top: 52px;
}

#textArea{
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
}
.content .tit{
    font-size: 15px;
    font-family: "Min Sans-Regular";
    color: #050409;
    margin-bottom: 4px;

}

#textArea::placeholder {
    color: #828284;
}


#textArea {
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
}

.saveNextBtn{
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
}

.leftAside{
    min-width: 320px;
    
}


.list_group .list:hover {
    color: #FF5F37;
}

.progress_container {
    width: 100%;
    background-color: #EDEDED;
    margin-bottom: 12px;
}

.progress_bar {
    width: 0%;
    height: 6px;
    background-color: #FF5F37;
}

.progress_info {
    font-size: 14px;
    color: #ff5f37;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 32px;
    font-family: "Min Sans-medium";
    letter-spacing: -0.3px;
}
#container h2{
    border-bottom: 1px solid #EDEDED;
}

.levelBar .level{
    display: flex;
    justify-content: space-between;
    font-size: 36px;
    font-family: "min sans-medium";
    margin-top: 72px;
    margin-bottom: 12px;
    letter-spacing: -0.3px;
}

.present{
    color: #050409;
}

.next{
    color: #BDBDBD;
}

.userInput {
    padding:32px 0;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
}
.userInput .top{
    display: flex;
    gap: 28px;
}

.top .inputRow{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
}

.bottom .inputRow{
    display: flex;
    flex-direction: column;
    

}



.inputRow label{
    margin-bottom: 8px;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
    font-family: "min sans-regular";
}


.checkButton{
    color: #fff;
    background-color: #FF5F37;
    font-size: 16px;
    letter-spacing: -0.3px;
    font-family: "min sans-regular";
    padding: 18px 20px;
    border: 1px solid #ff5f37;
    border-radius: 4px;
    cursor: pointer;
}

.checkButton:hover{
    background-color: #ff7957;
}
.action{
    margin-top: 32px;
}
.action > div{
    width:100%;
    padding:18px 20px;
    text-align: center;
    font-family: "min sans-medium";
    font-size: 16px;
    letter-spacing: -0.3px;
    border-radius: 28px;
    margin-bottom:16px;
    cursor: pointer;
}

.change{
    color: #fff;
    background-color: #ff5f37;
}

.change:hover{
    background-color: #ff7957;
}

.logout, .leave{
    background-color: #F6F6F6;
    color: #9B9B9D;
}

.logo:hover {
    cursor: pointer;
}


.change span, .change span svg{
    vertical-align: middle;
}
.bottom{
    width: calc(50% - 12px);
}
.overlap {
    display: flex;/* 이렇게 설정하면 아래 코드가 동작합니다. */

}

.overlap input[type="nickname"]{
    flex: 1;
    width: auto;
    margin-right: 12px;
}

.popup {
    display: none;
    /* 팝업 스타일을 설정합니다. */
}


@media(max-width:1400px){
    #container{
        padding: 0 80px;
        padding-top: 104px;
    }

}
@media (min-width: 768px) and (max-width: 820px) {
    .content{
        padding: 10px 10px 0 40px;
    }
}

@media(max-width:568px){
    .leftAside{
        display: none;
    }

    #wrap{
        display: block;
    }
    #container{
        padding: 0 22px;
        padding-top: 70px;
        padding-bottom: 70px;
    }


    #container h2{
        font-size: 24px;
        padding-bottom: 18px;
    }


    .userInput .top{
        flex-direction: column;
        gap: 0;
    }
    .bottom{
        width: 100%;
    }

    .levelBar .level{
        margin-top: 42px;
        margin-bottom: 6px;
        font-size: 20px;

    }

    .progress_info{
        margin-bottom: 42px;
    }

    .userInput{
        padding: 42px 0;
    }

    .action{
        margin-top: 42px;
    }

    .action > div{
        margin-bottom: 12px;
    }

    #progressInfo::after{
        content: url('../css/images/question-line\ 1.svg');
        vertical-align: middle;
        padding-left: 4px;
        cursor: pointer;
    
    }

}