@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


  .custom-navbar .custom-navbar-nav li.active a {
    opacity: 1;
  }
  .custom-navbar .custom-navbar-nav li.active a:before {
    width: calc(100% - 16px);
  }

  .synop-select-tab-nav li a.active {
    background: #040A20;
    color: #fff;
    border: 1px solid #040A20;
  }
  .rank-synop {
    padding: 0px 0px;
    padding-bottom: 120px;
    max-width: 1320px;
    margin: 0 auto;
  }

  @media (max-width: 1400px) {
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 14px;
      letter-spacing: -0.5px;
      margin: 0px 10px;
    }
  
    .rank-synop {
      max-width: 1296px;
  
    }
  
    .rank_illu {
      max-width: 1296px;
    }
  
    .footer {
      max-width: 1296px;
    }
  
    .selectTab{
      flex-direction: column;
      gap: 0;
    }
    .illu_view_sub .icon{
      display: none;
    }
    .illu_view_sub{
      display: flex;
      justify-content: flex-end;
    }
  
    .category{
      margin-bottom: 12px;
    }
    .illu_view{
      max-width: 100%;
      width: auto;
      height: auto;
    }
  
    
  
  }
  
  @media (max-width: 1100px) {
    .novel-view {
      width: 208px;
    }
    .synop-illu {
      height: 208px;
    }
  
    .illu_view {
      width: 260px;
    }
  }
  
  @media (max-width: 560px) {
    /**nav**/
    
    .custom-navbar {
      background-color: #fff;
      height: 50px;
      padding: 0 18px;
      margin: 0 auto;
      margin-bottom: 12px;
    }
  
    .nav-logo {
      height: 18px;
    }
  
    .navbar-brand {
      margin-left: 0px;
    }
  
    .navbar-toggler {
      padding: 4px 0px;
    }
  
    .navbar > .container {
      padding: 0px;
    }
  
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 17px;
      letter-spacing: -0.5px;
      margin: 12px 18px;
    }
  
    .navbar-collapse {
      position: absolute;
      z-index: 99;
      background-color: #fff;
      top: 60px;
      right: 20px;
      width: 100%;
    }
  
    .nav-link {
      padding: 0px 22px;
    }
  
    .custom-navbar .custom-navbar-nav li a:before {
      margin: 10px 14px;
      bottom: -16px;
    }
  
    /**slide**/
  
    .rank-synop {
      max-width: 540px;
    }
  
    .rank_illu {
      max-width: 540px;
    }
  
    .footer {
      max-width: 540px;
    }
  
    .rank_title {
      padding-top: 120px;
    }
  
    .container-l {
      max-width: 540px;
    }
  
    .container-d {
      max-width: 540px;
    }
  }
  
  @media(min-width:100px) and (max-width:768px){
    .selectTab{
      white-space: nowrap;
    }
  
    .selectTab > ul:first-of-type {
      overflow-x: auto;
    }
      .rank_title h1{
        font-size: 26px;
    }
  
    .rank_title h2{
      font-size: 12px;
    }
    
    .-view{
      width: 100%;
      height: auto;
      padding: 0.5rem !important; 
    }
    .synop-select-tab-nav li a{
      padding: 8px 12px;
    }
    
    .synop-select-tab-nav{
      margin-bottom: 0;
    }
  
    .category{
      margin-bottom: 10px;
    }
  
    .latest{
      margin-bottom: 20px;
    }
  
    .rank_title{
      padding-top:40px;
    }
  
    .container{
      padding: 0 18px;
    }
  
    .selectTab li a{
      font-size: 13px;
      padding: 8px 12px;
    }
   .rank-synop{
    padding-bottom: 80px;
   }
  
   .illu_view {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .illu_view > img {
  width: 100%;
  }
  
  .illu_view{
    padding: 0.65rem !important;
  }
  }
  