@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }



/**/
.header{
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
}
#container{
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
    padding-bottom: 100px;
}

.infoNav{
    border-bottom: 1px solid #EDEDED;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
}

.topInfo{
    display: flex;
    height: 66px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding: 0 320px;
    
    
}

.epiInfo{
    display: flex;
    font-family: "Min Sans-bold";
}

.back i {
    scale: 1.2;
    cursor: pointer;
}

.epiNum{
    padding-right:18px;
    padding-left: 20px;
}
.userSelect{
    display: flex;
}

.userSelect button{
    font-size: 15px;
    font-family: "Min Sans-Medium";
    color: #22222a;
    width: 100px;
    height: 36px;
    background-color: #F6F6F6;
    border: none;
    border-radius: 21px;
    margin-left: 8px;
    letter-spacing: -0.02em;
    cursor: pointer;
}

.userSelect .recommand{
    background-color: #fff;
    border: 1px solid #FF5F37;
    color: #FF5F37;
}
.epiTit{
    color:#FF5F37;
    font-size: 18px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    margin-bottom: 24px;
    margin-top: 24px;
}

.epiTable{
    border-collapse: collapse;
    width: 100%;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
}

.epiTable .num{
    width: 10%;
    text-align: center;
    color: #22222A;
    font-size: 18px;

}

.epiTable .title{
    width: 60%;
    color: #22222A;
    font-size: 18px;
    font-family: "Min Sans-Regular";

}

.epiTable .view{
    width: 12%;
    text-align: center;
    font-size: 16px;
    color: #A8A8A9;
}


.epiTable .date{
    width: 12%;
    text-align: center;
    font-size: 16px;
    color: #A8A8A9;
}

.epiTable tr/*:not(:last-of-type)*/ {
    border-bottom: 1px solid #ededed;
}

.epiTable tr:first-of-type{
    border-top: 1px solid #ededed;
}

.epiTable td{
    padding: 20px 0;
}

.hidden{
    display: none;
}

.loadMoreBtn{
    width: 100%;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    background-color: #F8F8F8;
    border: none;
    cursor: pointer;
}

.loadMoreBtn span{
    font-size: 16px;
    color: #22222A;
    letter-spacing: -2px;
    font-weight: 400;
    vertical-align: middle;
    
}
.list-group .list:hover {
    color: #FF5F37;
  }


@media(max-width:1386px){
    .epiTable .num{
        width: 10%;   
    }
    
    .epiTable .view{
        width: 20%;
    }
    
    
    .epiTable .date{
        width: 20%;
    }

}

@media(max-width: 1400px){
    #container{
        max-width:100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
        padding-bottom: 80px;
    }

    .topInfo{
        padding: 0px 120px;
    }
    .epiTable .num{
        width: 15%;   
    }

    .epiTable .view{
        width: 25%;
    }
    .epiTable .date{
        width: 25%;
    }

    .epiInfo{
        min-width: 100px;
        text-overflow: ellipsis;
    }
    .epiTitle{

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }


}

@media(min-width:100px) and (max-width: 768px) {
    #container{
        padding: 0 22px;
    }

    .topInfo{
        padding:0 18px;
        height: 50px;
    }
    .epiInfo{
        font-size: 15px;
    }

    .epiNum{
        padding: 0 12px;
    }

    .userSelect{
        display: none;
    }
    .contentTit{
        font-size: 17px;
        line-height: 2.4;
    }

    .content{
        font-size: 17px;
        line-height: 2.4;
    }

    .epiTable .view,
    .epiTable .date{
        display: none;
    }
    .story{
        font-size: 14px;
    }

    .bookIcon span{
        font-size: 13px;
    }
    .epiTit{
        font-size: 14px;
    }

    .epiTable .num,
    .epiTable .title{
        font-size: 14px;
    }

    .userAction span{
        font-size: 15px;
    }

    .epiTable .num {
        width: 12%;
    }
    .epiTable .view,
    .epiTable .date{
        display: none;
    }

}

