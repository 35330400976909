@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }


/**/
.synop_tab_group {
    width: 100%;
  }
  
  .synop_tab {
    margin-top: 108px;
    display: flex;
    justify-content: center;
  }
  
  .synop_tab_btn > a {
    padding: 12px 96px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #a8a8a9;
    text-align: center;
    position: relative;
    letter-spacing: -0.3px;
  }
  
  .synop_tab_btn_line {
    width: 100%;
    height: 4px;
    background-color: #ff5f37;
    margin-top: 12px;
  }
  
  .synop_tab_btn_u {
    text-align: center;
  }
  
  .synop_tab_btn_u > a {
    padding: 12px 0px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    color: #ff5f37;
    position: relative;
    letter-spacing: -0.3px;
  }

.leftAside .top{
    display: flex;
    gap: 12px;
}

.leftAside .content{
    padding: 54px 40px 0 40px;
}

.profile{
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
}

.topProfile{
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
}

.bottomProfile{
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
}

.coin, .block{
    display: flex;
    justify-content: space-between;
}

.bottomProfile .right{
    text-align: right;
    text-decoration: underline;
}

.my .tit{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.my .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color:#8F8F99;
    margin-left: 4px;
}

.list_group{
    font-family: "Min Sans-Regular";
}
.list:first-of-type{
    border-top: 1px solid #2C2C34;
}
.list{
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2C2C34;
    padding: 12px 0 12px 20px ;

}

.list:last-of-type{
    margin-bottom: 42px;
}

.list_group .down{
    margin-left: auto;

}

.list_group .cate{
    padding-right: 4px;
}

.my.edit .list{
    color: #8F8F99;
    font-family: "Min Sans-Regular";
}

.leftAside{
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
}
#container{
    overflow-y: auto; 
}

#wrap{
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto; 
    height: 100vh;
}

#container {
    padding: 0 140px;
}


.hidden_list {
    display: none;
}


.hidden_list li{
    padding: 6px 0;
    color: #8F8F99;
}

.hidden_list li:hover{
    color: #fff;
}

.list_group .list{
    cursor: pointer;
}

.content{
    padding-top: 52px;
}

#imageDescription{
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
}

#imageDescription::placeholder {
    color: #9B9B9D;
}


#imageDescription{
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
}

.saveNextBtn{
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    margin-bottom: 120px;
    cursor: pointer;
}

.saveNextBtn:hover{
    background-color: #303039;
}

.description_text{
    font-size: 14px;
    color: #828284;
    font-family: "Min Sans-Light";
    padding-left: 4px;
}



#imageDescription{
    margin-top: 12px;
    margin-bottom: 40px;
}
#imagePrice{
    margin-bottom: 52px;
    border: 1px solid #BDBDBD;
    margin-top: 12px;
}
.input_container{
    border-bottom: 1px solid #EDEDED;
}


#imageTitle{
    margin-bottom: 60px;
    border: 1px solid #BDBDBD;
}

.cateSelect ul {
    display: flex;
    gap: 10px;
    
}

@media (min-width: 100px) and (max-width: 359px) {
    .cateSelect ul {
        display: grid;
        gap: 10px;
    }
}
.cateSelect ul li {
    padding: 8px 20px;
    border: 1px solid #BDBDBD;
    border-radius: 19px;
    font-family: "Min Sans-Medium";
    font-size: 16px;
    text-align: center;
    color: #828284;
    cursor: pointer;
}

.cateSelect p {
    font-size: 18px;
    color: #050409;
    font-family: "Min Sans-Light";
    margin-bottom: 12px;

}
.cateSelect{
    margin-bottom: 60px;
    margin-top: 52px;

}

.cateSelect ul li.selected {
    background-color: #040A20;
    color: #fff;
    border: 1px solid #040A20;
}

.cateSelect ul li:hover {
    background-color: #040A20;
    color: #fff;
    border: 1px solid #040A20;

}

.bookCover{
    width: 300px;
    height: 476px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}

.bookCover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.input_container {
    position: relative;
}


.input_container::after {
    content: "C";
    position: absolute;
    top: 36px;
    right: 24px; 
    color: #ff5f37;
    font-size: 24px;
    font-family: "Min Sans-Light";
}

.leftAside{
    min-width: 320px;
}

.list_group .list:hover {
    color: #FF5F37;
  }
@media(max-width:1400px){
    #container{
        padding: 0 80px;
    }

    .saveNextBtn{
        margin-bottom: 120px;
    }
}

@media(max-width:568px){
    .leftAside{
        display: none;
    }
    #wrap{
        display: block;
    }
    #container{
        padding: 0 22px;
    }
    .synop_tab{
        margin-top: 70px;
    }

    .cateSelect{
        margin: 42px 0;
    }
    .cateSelect ul li{
        font-size: 13px;
        padding: 8px 12px;
    }

    .description_text{
        padding: 0;
        display: block;
        padding-top: 4px;
    }

    #imageTitle, #imagePrice{
        margin-bottom: 42px;
    }
    #imageDescription {
        margin-bottom: 20px;
    }
    .saveNextBtn{
        margin-top: 42px;
        margin-bottom: 80px;
    }

    .input_container::after{
        top: 32px;
    }
}