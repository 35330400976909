@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}



/**/

/**nav**/

.nav-logo {
    height: 24px;
  }
  
  .custom-navbar {
    background: #fff !important;
    height: 120px;
  }
  
  .navbar-brand {
    margin-left: 16px;
  }
  
  .custom-navbar .navbar-toggler {
    border-color: transparent;
  }
  .custom-navbar .navbar-toggler:active,
  .custom-navbar .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }
  
  .custom-navbar .custom-navbar-nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 0px 20px;
  }
  
  .custom-navbar .custom-navbar-nav li a:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 8px;
    right: 8px;
    background: #ff562c;
    height: 2px;
    opacity: 1;
    visibility: visible;
    width: 0;
    -webkit-transition: 0.15s all ease-out;
    -o-transition: 0.15s all ease-out;
    transition: 0.15s all ease-out;
  }
  
  .custom-navbar .custom-navbar-nav li a:hover {
    opacity: 1;
  }
  .custom-navbar .custom-navbar-nav li a:hover:before {
    width: calc(100% - 16px);
  }
  .custom-navbar .custom-navbar-nav li.active a {
    opacity: 1;
  }
  .custom-navbar .custom-navbar-nav li.active a:before {
    width: calc(100% - 16px);
  }
  .custom-navbar .custom-navbar-cta {
    margin-left: 0 !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .custom-navbar .custom-navbar-cta {
    margin-left: 20px !important;
  }
  
  .custom-navbar .custom-navbar-cta li {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .custom-navbar .custom-navbar-cta li:first-of-type {
    margin-right: 20px;
  }
  
  /**slide**/
  
  .carousel {
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .carousel-inner {
    width: 100%;
    display: flex;
    transition: transform 0.8s ease-in-out;
  }
  
  .carousel-inner .carousel-item {
    padding: 0px 10px;
  }
  
  .carousel-inner .carousel-item > img {
    border-radius: 20px;
  }
  
  .carousel-indicators {
    right: 10px;
  }
  
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .carousel-control-next,
  .carousel-control-prev {
    padding: 0px 0px;
    width: 80px;
  }

  /*content*/

#container{
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
    
}

.topInfo{
    display: flex;
    align-items: flex-end;
    gap: 38px;
  margin-bottom: 124px;
    max-width: 100%;
    height: auto;
    margin-top: 46px;
}


.cate{
    font-size: 16px;
    background-color: #22222a;
    color: #fff;
    padding: 6px 16px;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
}

.bookTitle{
    font-size: 38px;
    color: #050409;
    font-weight: 700;
    letter-spacing: -0.03em;
    margin-bottom: 8px;;
    margin-top: 12px;
    font-family: "Min Sans-bold";
}



.seriesInfo{
    font-size: 18px;
    letter-spacing: -0.03em;
    padding-bottom: 10px;
    font-family: "Min Sans-Regular";
}

.autorName{
    color: #22222a;
    padding-right: 8px;
    letter-spacing: -0.03em;
}

.day{
    color: #828284;
    padding-right: 8px;
    letter-spacing: -0.03em;
}

.bookNum{
    color: #828284;
    letter-spacing: -0.03em;
}

.story{
    color: #22222a;
    line-height: 1.6;
    font-size: 16px;
    letter-spacing: -0.03em;
    padding-bottom: 16px;
    font-family: "Min Sans-Light";
}

.bookIcon >span{
    padding-right: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
}


.btn {
    background-color: #F6F6F6;
    border-radius: 20px;
    border: none;
}

.userAction{
  margin-top: 10px;
  
}

.userAction span{
    font-size: 15px;
    letter-spacing: -0.3px;
    font-family: "Min Sans-Medium";

}

.userAction button{
    cursor: pointer;
    border-radius: 26px;
    margin: 0;
    margin-right: 6px;
}

.buy{
    background-color: #FF5F37;
    border: none;
    color:#fff;
    border: 1px solid #FF5F37;
    padding: 12px 50px;

}

.declareBtn{
    background-color: #f6f6f6;
    color: #A8A8A9;
    border: 1px solid #f6f6f6;
    padding: 12px 20px;
}
.line{
    border: 1px solid #ededed;
    
}

.price{
  color:#FF5F37;
  font-family: "min sans-regular";
  display: flex;
  align-items: center;
  gap:4px;
  letter-spacing: -0.3px;
  margin-top: 20px;
}
  .price span {
    font-size: 16px;
  }

  .price h1{
    font-size: 28px;
    font-family: "min sans-bold";
  }


.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 배경색 및 투명도 설정 */
    z-index: 1; /* 모달을 다른 요소 위에 표시 */
  }
  
  .modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 20px;
    max-width: 330px;
  }

  .modal p {
    font-size: 18px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    color: #22222A;
    margin-bottom: 24px;
  }
  
  /* 모달 버튼 스타일 */
  
  /* 모달 닫기 버튼 스타일 */
  #cancelBtn {
    background-color: #f8f8f8;
    color: #22222A;
  }

  #reportBtn{
    background-color: #FF5F37;
    color: #fff;
  }
  



  /**footer**/

.rank-free {
    padding: 0px 0px;
    padding-bottom: 120px;
    max-width: 1320px;
    margin: 0 auto;
  }
  
  .container-l {
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
  }

  .footer {
    padding: 44px 0px;
    max-width: 1320px;
    margin: 0 auto;
  }
  
  .footer-img {
    height: 20px;
    width: auto;
    margin-bottom: 20px;
  }
  
  .footer-img > img {
    width: 100%;
  }
  
  .footer-text-1 {
    font-family: "Min Sans-Regular";
    font-size: 13px;
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
  }
  
  .footer-text-2 {
    font-family: "Min Sans-Regular";
    font-size: 13px;
    color: #050409;
    letter-spacing: -0.3px;
    opacity: 0.5;
  }
  
  .footer-text-3 {
    margin-top: 20px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    font-size: 13px;
  }
  
  .footer-text-3 > a {
    color: #050409;
    text-decoration: none;
    margin-right: 12px;
  }
  
  .list-group .list:hover {
    color: #FF5F37;
  }


@media (max-width: 1400px) {
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 14px;
      letter-spacing: -0.5px;
      margin: 0px 10px;
    }
  
    .rank-synop {
      max-width: 1296px;
    }
  
    .rank-illu {
      max-width: 1296px;
    }
  
    .footer {
      max-width: 1296px;
    }
    #container{
      padding: 0 140px;
    }
  }

  @media (max-width: 560px) {
    /**nav**/
    .custom-navbar {
      background-color: #fff;
      height: 50px;
      padding: 0 18px;
      margin: 0 auto;
      margin-bottom: 12px;
    }
  
    .nav-logo {
      height: 18px;
    }
  
    .navbar-brand {
      margin-left: 0px;
    }
  
    .navbar-toggler {
      padding: 4px 0px;
    }
  
    .navbar > .container {
      padding: 0px;
    }
  
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 17px;
      letter-spacing: -0.5px;
      margin: 12px 18px;
    }
  
    .navbar-collapse {
      position: absolute;
      z-index: 99;
      background-color: #fff;
      top: 60px;
      right: 20px;
      width: 100%;
    }
  
    .nav-link {
      padding: 0px 22px;
    }
  
    .custom-navbar .custom-navbar-nav li a:before {
      margin: 10px 14px;
      bottom: -16px;
    }
  
    /**slide**/
  
    .rank-synop {
      max-width: 540px;
    }
  
    .rank-illu {
      max-width: 540px;
    }
  
    .footer {
      max-width: 540px;
    }
  
    .rank-title {
      padding-top: 120px;
    }
  
    .container-l {
      max-width: 540px;
    }
  
    .container-d {
      max-width: 540px;
    }
  }

 @media(min-width:100px) and (max-width:768px){
    #container{
      padding: 0 22px;
    }

    .bookTitle{
      font-size: 22px;
    }

    #container{
      padding: 0 22px;
  }

  .topInfo{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
  }
  .epiInfo{
      font-size: 15px;
  }

  .epiNum{
      padding: 0 12px;
  }

  .userSelect{
      display: none;
  }
  .contentTit{
      font-size: 17px;
      line-height: 2.4;
  }


  .cate {display: none;}

  .seriesInfo{
      font-size: 13px;
  }

  .story{
      font-size: 14px;
  }
  .bookImg img{
      max-width: 100%;
      height: 100;
  }
  .bookIcon span{
      font-size: 13px;
  }
  .epiTit{
      font-size: 14px;
  }

  .epiTable .num,
  .epiTable .title{
      font-size: 14px;
  }

  .userAction span{
      font-size: 15px;
  }

  .topInfo{
    margin-bottom: 80px;
  }
  }
  
