/**modal**/
.swal2-title {
    font-size: 24px !important;
    font-family: "Min Sans-bold";
    color: #050409 !important;
  }
  
  .swal2-html-container {
    margin-top: 10px;
    font-size: 18px;
    font-family: "Min Sans-Regular";
    color: #828284;
  }
  
  .swal2-popup {
    padding: 2.25rem !important;
  }
  
  .swal2-confirm {
    font-size: 16px;
    font-family: "Min Sans-Regular";
    color: #828284;
  }
  
  .swal2-cancel {
    font-size: 16px;
    font-family: "Min Sans-Regular";
    color: #828284;
  }
  .swal2-footer {
    font-family: "Min Sans-Regular";
  }