@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


.col {
  padding: 0px;
}
/**nav**/

.nav-logo {
  height: 24px;
}

.custom-navbar {
  background: #fff !important;
  height: 120px;
}

.navbar-brand {
  margin-left: 16px;
}

.custom-navbar .navbar-toggler {
  border-color: transparent;
}
.custom-navbar .navbar-toggler:active,
.custom-navbar .navbar-toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.custom-navbar .custom-navbar-nav li a {
  color: #050409 !important;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
}

.custom-navbar .custom-navbar-nav li a:before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 8px;
  right: 8px;
  background: #ff562c;
  height: 2px;
  opacity: 1;
  visibility: visible;
  width: 0;
  -webkit-transition: 0.15s all ease-out;
  -o-transition: 0.15s all ease-out;
  transition: 0.15s all ease-out;
}

.custom-navbar .custom-navbar-nav li a:hover {
  opacity: 1;
}
.custom-navbar .custom-navbar-nav li a:hover:before {
  width: calc(100% - 16px);
}
.custom-navbar .custom-navbar-nav li.active a {
  opacity: 1;
}
.custom-navbar .custom-navbar-nav li.active a:before {
  width: calc(100% - 16px);
}
.custom-navbar .custom-navbar-cta {
  margin-left: 0 !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.custom-navbar .custom-navbar-cta {
  margin-left: 20px !important;
}

.custom-navbar .custom-navbar-cta li {
  margin-left: 0px;
  margin-right: 0px;
}

.custom-navbar .custom-navbar-cta li:first-of-type {
  margin-right: 20px;
}

/**slide**/

.carousel {
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-inner {
  width: 100%;
  display: flex;
  transition: transform 0.8s ease-in-out;
}

.carousel-inner .carousel-item {
  padding: 0px 10px;
}

.carousel-inner .carousel-item > img {
  border-radius: 20px;
}

.carousel-indicators {
  right: 10px;
}

.carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.carousel-control-next,
.carousel-control-prev {
  padding: 0px 0px;
  width: 80px;
}

/**veteran**/

.rank_veteran {
  padding: 0px 0px;
  margin-bottom: 120px;
}
.rank_veteran h1{
  padding-bottom: 22px;
  padding-top: 8px;
}

.rank_title h1{
  margin-bottom: 0;
}
.rank_title h2{
  margin-bottom: 0;
}
.novel-view {
  width: 270px;
}

.novel-view > img {
  width: 100%;
}

.novel-view-title {
  font-size: 17px;
  font-family: "Min Sans-Medium";
  color: #050409;
  letter-spacing: -0.3px;
  margin-top: 12px;
}

.novel-view-sub {
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #9b9b9d;
  letter-spacing: -0.3px;
}



.rank_title {
  padding-top: 60px;
}

/**free**/
.rank-free {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1272x;
  margin: 0 auto;
}

.container-l {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

.selectTab{
  display: flex;
  gap: 48px;
}


/*synop-tab*/


.synop-select-tab-nav{
  display: flex;
  padding: 0;
  margin-bottom: 24px;
}
.synop-select-tab-nav li a:hover,
.synop-select-tab-nav li a.active {
  background: #040A20;
  color: #fff;
  border: 1px solid #040A20;
}

.synop-select-tab-content {
  height: auto;
  border-top: none;
}

.synop-select-tab-nav li {
  display: inline-block;
  text-align: center;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #22222a;
}
.synop-select-tab-nav li a {
  position: relative;
  display: block;
  color: #22222a;
  padding: 8px 20px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid #22222a;
}
/**페이지네이션**/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.pagination a {
  text-decoration: none;
  margin: 5px;
  padding: 5px 10px;
  color: #333;
  font-family: "Min Sans-Light";
  font-size: 14px;
}

.pagination a:hover {
  background-color: #fff;
  color: #ff5f37;
}

.current-page {
  color: #ff5f37 !important;
}




/**footer**/
.footer {
  padding: 44px 0px;
  max-width: 1320px;
  margin: 0 auto;
}

.footer-img {
  height: 20px;
  width: auto;
  margin-bottom: 20px;
}

.footer-img > img {
  width: 100%;
}

.footer-text-1 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.footer-text-2 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  opacity: 0.5;
}

.footer-text-3 {
  margin-top: 20px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  font-size: 13px;
}

.footer-text-3 > a {
  color: #050409;
  text-decoration: none;
  margin-right: 12px;
}

@media (max-width: 1400px) {
  .custom-navbar .custom-navbar-nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 14px;
    letter-spacing: -0.5px;
    margin: 0px 10px;
  }

  .rank-synop {
    max-width: 1296px;
    padding-bottom: 0;
  }

  .rank-illu {
    max-width: 1296px;
  }

  .footer {
    max-width: 1296px;
  }
  .selectTab{
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .novel-view{
    width: 100%;
    height: auto;
    padding: 0.5rem !important; 
  }

  .category{
    margin-bottom: 12px;
  }
}

@media (max-width: 560px) {
  /**nav**/
  .custom-navbar {
    background-color: #fff;
    height: 50px;
    padding: 0 18px;
    margin: 0 auto;
    margin-bottom: 12px;
  }

  .nav-logo {
    height: 18px;
  }

  .navbar-brand {
    margin-left: 0px;
  }

  .navbar-toggler {
    padding: 4px 0px;
  }

  .navbar > .container {
    padding: 0px;
  }

  .custom-navbar .custom-navbar-nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 12px 18px;
  }

  .navbar-collapse {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    top: 60px;
    right: 20px;
    width: 100%;
  }

  .nav-link {
    padding: 0px 22px;
  }

  .custom-navbar .custom-navbar-nav li a:before {
    margin: 10px 14px;
    bottom: -16px;
  }

  /**slide**/

  .rank-synop {
    max-width: 540px;
  }

  .rank-illu {
    max-width: 540px;
  }

  .footer {
    max-width: 540px;
  }

  .rank_title {
    padding-top: 120px;
  }

  .container-l {
    max-width: 540px;
  }

  .container-d {
    max-width: 540px;
  }
}

@media(max-width:568px){
  .selectTab{
    white-space: nowrap;
  }

  .selectTab > ul:first-of-type {
    overflow-x: auto;
  }

  .novel-view{
    width: 100%;
    height: auto;
    padding: 0.5rem !important; 
  }
  .synop-select-tab-nav li a{
    padding: 8px 12px;
  }
  
  .synop-select-tab-nav{
    margin-bottom: 0;
  }

  .category{
    margin-bottom: 10px;
  }

  .latest{
    margin-bottom: 20px;
  }

  .rank_title{
    padding-top:40px;
  }

  .rank_title h1{
    font-size: 26px;
  }
  .rank_title h2{
    font-size: 12px;
  }

  .container{
    padding: 0 18px;
  }



  .selectTab li a{
    font-size: 13px;
    padding: 8px 12px;
  }

    /**container**/
  
    .rank_veteran{
      margin-bottom: 80px;
    }
  
    .username{
      padding-left: 4px;  
    }
}
