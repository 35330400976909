@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }

.leftAside .top{
    display: flex;
    gap: 12px;
}

.leftAside .content{
    padding: 54px 40px 0 40px;
}


.profile{
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
}

.topProfile{
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
}

.bottomProfile{
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
}

.coin, .block{
    display: flex;
    justify-content: space-between;
}

.bottomProfile .right{
    text-align: right;
    text-decoration: underline;
}

.my .tit{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.my .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color:#8F8F99;
    margin-left: 4px;
}

.list_group{
    font-family: "Min Sans-Regular";
}
.list:first-of-type{
    border-top: 1px solid #2C2C34;
}
.list{
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2C2C34;
    padding: 12px 0 12px 20px ;

}

.list:last-of-type{
    margin-bottom: 42px;
}

.list_group .down{
    margin-left: auto;

}

.list_group .cate{
    padding-right: 4px;
}

.my.edit .list{
    color: #8F8F99;
    font-family: "Min Sans-Regular";
}

.leftAside, .container{
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
}

#wrap{
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto; 
    height: 100vh;
}

#container {
    padding: 0 140px;
}


.hidden_list {
    display: none;
    margin-top: 6px;
}


.hidden_list li{
    padding: 6px 0;
    color: #8F8F99;
}

.hidden_list li:hover{
    color: #fff;
}

.list_group .list{
    cursor: pointer;
}

.content{
    padding-top: 52px;
}

#textArea{
    border: 1px solid #BDBDBD;
    padding: 18px 20px;
    width: 100%;
    height: 174px;
    outline: none;
    font-size: 16px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
}
.content .tit{
    font-size: 15px;
    font-family: "Min Sans-Regular";
    color: #050409;
    margin-bottom: 4px;
    letter-spacing: -0.03em;

}

#textArea::placeholder {
    color: #828284;
}


#textArea {
    color: #22222A;
    line-height: 1.5;
    letter-spacing: -0.03em;
}

.saveNextBtn{
    color: #fff;
    background-color: #22222A;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    margin-top: 60px;
    width: 100%;
    padding: 24px;
    border: none;
    cursor: pointer;
}

.saveNextBtn:hover{
    background-color: #303039;
}

.leftAside{
    min-width: 320px;
}


.small{
    display: none;
}

.big{display: block;}


.list_group .list:hover {
    color: #FF5F37;
  }
@media(max-width:1400px){
    #container{
        padding: 0 80px;
    }

    #container, .leftAside{
        overflow-y: auto; 
    }

    /*
    .big{
        display: none;
    }
    .small{
        display: block;
    }

    .person{
        margin-top: 52px;
        letter-spacing: -0.3px;
        color: #22222A;
        font-size: 18px;
        font-family: "Min Sans-Light";
        margin-bottom: 10px;
    }

    .input-container {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #22222A;
        padding: 42px 36px;
    }

    .input-container > div {
        flex: 1;
        margin: 12px;
    }

    label {
        display: block;
        margin-bottom: 4px;
        font-size:15px;
        letter-spacing: -0.3px;
        color: #050409;
        font-family: "Min Sans-Regular";
    }

    input[type="text"] {
        width: 100%;
        padding:  18px 20px;
        border: 1px solid #BDBDBD;
        outline: none;
        color: #22222A;
        font-size: 16px;
        font-family: "Min Sans-Regular";
        letter-spacing: -0.3px;
        text-overflow: ellipsis;
    }

    input::placeholder{
        color: #828284;
        font-family: "Min Sans-Regular";
        font-size:16px;
        letter-spacing: -0.3px;

    }

    #addPersonBtn{
        color: #fff;
        font-size: 16px;
        letter-spacing: -0.3px;
        font-family: "Min Sans-Regular";
        background-color: #ff5f37;
        border: none;
        border-radius: 22px;
        padding:12px 41px;
        float: right;
        margin-top: 16px;

    }

    .saveNextBtn{
            margin-bottom: 120px;
    }
*/

}

@media(max-width:568px){
    .leftAside{
        display: none;
    }

    #wrap{
        display: block;
    }
    #container{
        padding: 0 22px;
    }



    .content{
        padding-top: 42px;
    }

    .saveNextBtn{
        margin-top: 42px;
    }
}
