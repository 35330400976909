@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


/*body*/

/**nav**/

.nav-logo {
    height: 24px;
  }
  
  .custom-navbar {
    background: #fff !important;
    height: 120px;
  }
  
  .navbar-brand {
    margin-left: 16px;
  }
  
  .custom-navbar .navbar-toggler {
    border-color: transparent;
  }
  .custom-navbar .navbar-toggler:active,
  .custom-navbar .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }
  
  .custom-navbar .custom-navbar-nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 0px 20px;
  }
  
  .custom-navbar .custom-navbar-nav li a:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 8px;
    right: 8px;
    background: #ff562c;
    height: 2px;
    opacity: 1;
    visibility: visible;
    width: 0;
    -webkit-transition: 0.15s all ease-out;
    -o-transition: 0.15s all ease-out;
    transition: 0.15s all ease-out;
  }
  
  .custom-navbar .custom-navbar-nav li a:hover {
    opacity: 1;
  }
  .custom-navbar .custom-navbar-nav li a:hover:before {
    width: calc(100% - 16px);
  }
  .custom-navbar .custom-navbar-nav li.active a {
    opacity: 1;
  }
  .custom-navbar .custom-navbar-nav li.active a:before {
    width: calc(100% - 16px);
  }
  .custom-navbar .custom-navbar-cta {
    margin-left: 0 !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .custom-navbar .custom-navbar-cta {
    margin-left: 20px !important;
  }
  
  .custom-navbar .custom-navbar-cta li {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .custom-navbar .custom-navbar-cta li:first-of-type {
    margin-right: 20px;
  }
  
  /**slide**/
  
  .carousel {
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .carousel-inner {
    width: 100%;
    display: flex;
    transition: transform 0.8s ease-in-out;
  }
  
  .carousel-inner .carousel-item {
    padding: 0px 10px;
  }
  
  .carousel-inner .carousel-item > img {
    border-radius: 20px;
  }
  
  .carousel-indicators {
    right: 10px;
  }
  
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .carousel-control-next,
  .carousel-control-prev {
    padding: 0px 0px;
    width: 80px;
  }

  /* cotent */

#container{
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 0px 320px;
}
.mainBanner img{
  width: 100%;
}
.mainBanner{
  padding-bottom: 42px;
}

.title{
  font-family: "min sans-medium";
  font-size: 20px;
  letter-spacing: -0.3px;
  color: #050409;
  margin-bottom: 12px;
}


.column{
  column-count: 4;
  gap: 24px;
  margin-bottom: 32px;
}
.content{
  padding: 28px;
  background-color: #F8F8F8;
  border-radius: 20px;
}
.package{
  background-color: #040A20;
  border-radius: 31px;  
  color: #fff;
  justify-content: center;
  gap:6px;
  align-self: center;
  padding: 11px 0 12px 0;
}

.package p {
  margin-bottom: 0;
  font-size: 18px;
  font-family: "min sans-bold";
  letter-spacing: -0.3px;
}

.descipt p {
  margin-bottom: 0;
}
.payment{
  background-color: #FF5F37;
  color: #fff;
  text-align: center;
  padding: 11px 0 12px 0;
  border-radius: 31px;
  cursor: pointer;
}

.payment:hover{
  background-color: #ff7957;
}
.payment span{
  font-size: 15px;
  font-family: "min sans-medium";
  letter-spacing: -0.3px;
}
.priceTab {
  text-align: right;
  padding-top: 28px;
  padding-bottom: 12px;
}

.priceTab p{
  font-size: 15px;
  font-family: "Min Sans-regular";
  color: #9B9B9D;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.percent{
  font-size: 16px;
  font-family: "Min Sans-bold";
  color: #FF5F37;
  letter-spacing: -0.3px;
}

.discount{
  font-size: 16px;
  font-family: "Min Sans-bold";
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: -0.3px;
  position: relative;
  display: inline-block;
}

.discount::before{
  content: '';
  height: 1px;
  background-color: #828284;
  display: block;
  position: absolute;
  top: 50%;
  left: 0; 
  right: 0;
  transform: translateY(-50%); 
}


.money, .won{
  font-size: 22px;
  font-family: "Min Sans-bold";
  color: #050409;
  letter-spacing: -0.3px;
}


  .rank_title {
    padding-top: 60px;
  }

  .rank_title h1 {
    font-size: 46px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    padding-bottom: 20px;

  }
  
  .rank_title h2 {
    font-size: 20px;
    font-family: "Min Sans-bold";
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 10px;
  }

  


.information{
  padding-top: 24px;
  padding-bottom: 110px;
}
.information p{
  color: #828284;
  font-size: 14px;
  letter-spacing: -0.3px;
  font-family: "min sans-regular";
}
.information .tit{
  font-size: 16px;
}


/**footer**/

.rank-free {
    padding: 0px 0px;
    padding-bottom: 120px;
    max-width: 1320px;
    margin: 0 auto;
  }
  
  .container-l {
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
  }

  .footer {
    padding: 44px 0px;
    max-width: 1320px;
    margin: 0 auto;
  }
  
  .footer-img {
    height: 20px;
    width: auto;
    margin-bottom: 20px;
  }
  
  .footer-img > img {
    width: 100%;
  }
  
  .footer-text-1 {
    font-family: "Min Sans-Regular";
    font-size: 13px;
    color: #050409;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
  }
  
  .footer-text-2 {
    font-family: "Min Sans-Regular";
    font-size: 13px;
    color: #050409;
    letter-spacing: -0.3px;
    opacity: 0.5;
  }
  
  .footer-text-3 {
    margin-top: 20px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.3px;
    font-size: 13px;
  }
  
  .footer-text-3 > a {
    color: #050409;
    text-decoration: none;
    margin-right: 12px;
  }
  
  .list-group .list:hover {
    color: #FF5F37;
  }

  .information .text{
    line-height: 1.6;
  }

  .charge.premium{
    margin-top: 32px;
  }

@media (max-width: 1400px) {

  
  
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 14px;
      letter-spacing: -0.5px;
      margin: 0px 10px;
    }
  
    .rank-synop {
      max-width: 1296px;
    }
  
    .rank-illu {
      max-width: 1296px;
    }
  
    .footer {
      max-width: 1296px;
    }
  }

  @media (max-width: 560px) {
    /**nav**/
    .custom-navbar {
      background-color: #fff;
      height: 50px;
      padding: 0 18px;
      margin: 0 auto;
      margin-bottom: 12px;
    }
  
    .nav-logo {
      height: 18px;
    }
  
    .navbar-brand {
      margin-left: 0px;
    }
  
    .navbar-toggler {
      padding: 4px 0px;
    }
  
    .navbar > .container {
      padding: 0px;
    }
  
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 17px;
      letter-spacing: -0.5px;
      margin: 12px 18px;
    }
  
    .navbar-collapse {
      position: absolute;
      z-index: 99;
      background-color: #fff;
      top: 60px;
      right: 20px;
      width: 100%;
    }
  
    .nav-link {
      padding: 0px 22px;
    }
  
    .custom-navbar .custom-navbar-nav li a:before {
      margin: 10px 14px;
      bottom: -16px;
    }
  
    /**slide**/
  
    .rank-synop {
      max-width: 540px;
    }
  
    .rank-illu {
      max-width: 540px;
    }
  
    .footer {
      max-width: 540px;
    }
  
    .rank_title {
      padding-top: 120px;
    }
  
    .container-l {
      max-width: 540px;
    }
  
    .container-d {
      max-width: 540px;
    }

    .logBox{
        padding-top: 48px;
    }
  }

  .content {
    width: calc(25% - 12px); /* 4개 중 하나의 너비 */
    float: left; /* 좌측 정렬 */
  }
  
  /* 화면 너비가 1400px 이하인 경우: 2개씩 2줄 배치 */
  @media (max-width: 1400px) {
    .content {
      width: calc(50% - 12px);  /* 2개 중 하나의 너비 */
      
    }

    .charge{
      max-width: 624px;
      margin: 0 auto;
    }
  }
  
  /* 화면 너비가 768px 이하인 경우: 1개씩 4줄 배치 */
  @media (max-width: 568px) {
    .content {
      width:100%;
    }
    .container{
      padding: 0 18px;
    }
    .charge{
      max-width: 300px;
    }

    .rank_title{
      padding-top: 40px;
    }

    .rank_title h1{
      font-size: 26px;
    }
    .rank_title h2{
      font-size: 12px;
      margin-bottom: 4px;

    }
    .mainBanner{
      margin: 0 -26px;
    }
    .mainBanner img{
      width: 100%;
      height: auto;
    }

    .charge .title{
      font-size: 16px;
      margin-bottom: 20px;
    }

    .basic .content{
      margin-bottom: 12px;
    }
    .information .tit{
      margin-bottom: 12px;
    }

    .information{
      padding-bottom: 80px;
    }

    .information p {
      line-height: 1.6;
    }
}


.basic{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

}

@media(max-width:360px){
  .content {
    width:100%;
  }
}


