@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}

/**/

.logo {
  height: 24px;
}

.logo > img {
  height: 100%;
}

.leftAside .top {
  display: flex;
  gap: 12px;
}

.leftAside .content {
  padding: 54px 40px 0 40px;
}


.profile {
  border: 1px solid #464651;
  border-radius: 12px;
  padding: 20px;
  margin-top: 46px;
  margin-bottom: 42px;
}

.topProfile {
  color: #fff;
  display: flex;
  font-size: 18px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  padding-bottom: 24px;
  gap: 4px;
}

.bottomProfile {
  color: #ff5f37;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.03em;
  gap: 8px;
}

.coin,
.block {
  display: flex;
  justify-content: space-between;
}

.bottomProfile .right {
  text-align: right;
  text-decoration: underline;
}

.my .tit {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.my .tit p {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  color: #8f8f99;
  margin-left: 4px;
}

.list_group {
  font-family: "Min Sans-Regular";
}
.list:first-of-type {
  border-top: 1px solid #2c2c34;
}
.list {
  display: flex;
  color: #fff;
  border-bottom: 1px solid #2c2c34;
  padding: 12px 0 12px 20px;
}

.list:last-of-type {
  margin-bottom: 42px;
}

.list_group .down {
  margin-left: auto;
}

.list_group .cate {
  padding-right: 4px;
}

.my.edit .list {
  color: #8f8f99;
  font-family: "Min Sans-Regular";
}

#wrap {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  height: 100vh;
}

.leftAside,
.container,
.rightAside {
  background-color: #22222a;
  height: 100%;
  overflow-y: auto;
}

/**container**/



#container {
  padding: 0 280px;
  padding-top: 104px;
  overflow-y: auto;
  
}

.synop_tab_group {
  width: 100%;
}



.synop_select_title {
  font-family: "Min Sans-bold";
  font-size: 32px;
  letter-spacing: -0.3px;
  padding-bottom: 28px;
  border-bottom: 1px solid #EDEDED

}

.synop_select_tab > ul {
  list-style: none;
  margin-top: 52px;
  margin-bottom: 32px;
}

.synop_select_tab > li {
  list-style: none;
}

.synop_select_tab_nav li {
  display: inline-block;
  text-align: center;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #22222a;
}

.synop_select_tab_nav li a.active {
  border-bottom: 1px solid #fff;
}
.synop_select_tab_nav li a {
  position: relative;
  display: block;
  color: #22222a;
  padding: 8px 20px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid #22222a;
}
.synop_select_tab_nav li a:hover,
.synop_select_tab_nav li a.active {
  background: #22222a;
  color: #fff;
  border: 1px solid #22222a;
}


/**synopsis content**/
.rank_synop {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
}
/** table **/

#container .all{

  text-align: center;

  border-collapse: collapse;
  color: #22222a;
  font-size: 15px;
  font-family: "min sans-regular";
  letter-spacing: -.3px;
  white-space: nowrap;
  overflow-x: auto;
  display: block;



}


.clock{
  padding: 10px;
}

.info{
  width:40%
}
.all::-webkit-scrollbar{
  height: 6px;
  background-color: #ededed;
}
.all::-webkit-scrollbar-thumb{
  background-color: #d9d9d9;
  border-radius: 5px;

}

/**페이지네이션**/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.pagination a {
  text-decoration: none;
  margin: 5px;
  padding: 5px 10px;
  color: #333;
  font-family: "Min Sans-Light";
  font-size: 14px;
}

.pagination a:hover {
  background-color: #fff;
  color: #ff5f37;
}

.current_page {
  color: #ff5f37 !important;
}

.leftAside{
  min-width: 320px;
  
}
@media (max-width: 1400px) {

  .synop_select_tab_nav li a {
    padding: 8px 16px;
    font-size: 14px;
  }

  #container{
    padding: 0 80px;
    padding-top: 104px;
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .leftAside,
  .rightAside {
    display: none;
  }

  #wrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  #container {
    padding: 0 22px;
    padding-top: 70px;
  }

  .rank_synop{
    padding-bottom: 80px;
  }

  .all{
    overflow-x: auto;
  }


.synop_select_title{
    font-size: 24px;
    padding-bottom: 18px;
}

.content{
    padding-top: 42px;
}
.synop_select_tab > ul{
  margin-top: 42px;
  margin-bottom: 20px;
}

.synop_select_tab_nav li a{
  margin-right: 6px;
}


}


