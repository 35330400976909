@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}



.col {
  padding: 0px;
}
/**nav**/

.nav_logo {
  height: 24px;
}

.custom_navbar {
  background: #fff !important;
  height: 120px;
}

.navbar_brand {
  margin-left: 16px;
}

.custom_navbar .navbar_toggler {
  border-color: transparent;
}
.custom_navbar .navbar_toggler:active,
.custom_navbar .navbar_toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.custom_navbar .custom_navbar-nav li a {
  color: #050409 !important;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
}

.custom_navbar .custom_navbar_nav li a:before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 8px;
  right: 8px;
  background: #ff562c;
  height: 2px;
  opacity: 1;
  visibility: visible;
  width: 0;
  -webkit-transition: 0.15s all ease-out;
  -o-transition: 0.15s all ease-out;
  transition: 0.15s all ease-out;
}

.custom_navbar .custom_navbar_nav li a:hover {
  opacity: 1;
}
.custom_navbar .custom_navbar_nav li a:hover:before {
  width: calc(100% - 16px);
}
.custom_navbar .custom_navbar_nav li.active a {
  opacity: 1;
}
.custom_navbar .custom_navbar_nav li.active a:before {
  width: calc(100% - 16px);
}
.custom_navbar .custom_navbar_cta {
  margin-left: 0 !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.custom_navbar .custom-navbar_cta {
  margin-left: 20px !important;
}

.custom_navbar .custom_navbar_cta li {
  margin-left: 0px;
  margin-right: 0px;
}

.custom_navbar .custom-navbar_cta li:first-of-type {
  margin-right: 20px;
}

/**slide**/

.carousel {
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel_item {
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel_inner {
  width: 100%;
  display: flex;
  transition: transform 0.8s ease-in-out;
}

.carousel_inner .carousel_item {
  padding: 0px 10px;
}

.carousel_inner .carousel_item > img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.carousel_indicators {
  right: 10px;
}

.carousel_indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.carousel_control-next,
.carousel_control-prev {
  padding: 0px 0px;
  width: 80px;
}

/**veteran**/

.rank_veteran {
  padding: 0px 0px;
  margin-bottom: 120px;
}

.novel_view {
  width: 270px;
  height: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.novel_view > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.novel_view_title {
  font-size: 17px;
  font-family: "Min Sans-Medium";
  color: #050409;
  letter-spacing: -0.3px;
  margin-top: 12px;
}

.novel_view_sub {
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #9b9b9d;
  letter-spacing: -0.3px;
}

.username {
  margin-left: 6px;
}

.rank_title {
  padding-top: 120px;
}

/**free**/
.rank_free {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
}

.container_l {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

/**synopsis**/
.rank_synop {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
}

.container_d {
  margin: 0;
  padding: 0;
  background: radial-gradient(circle, #070709, #22222a);
  background-size: 150% 150%;
}

.rank_title_d {
  padding-top: 120px;
}

.rank_title_d > h1 {
  color: #fff;
}

.rank_title_d > h2 {
  color: #fff;
}
 
.synop_img {
  position: relative;
}

.synop_overlay {
  position: absolute;
  top: 170px;
  left: 20px;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
}

.synop_illu {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  opacity: 0.4;
  border-radius: 20px;
}

.synop_title {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.3px;
  opacity: 0.9;
  margin-bottom: 6px;
}

.synop_sub {
  font-size: 15px;
  font-family: "Min Sans-Light";
  letter-spacing: -0.3px;
  opacity: 0.7;
  width: 356px;
}

/**illu**/
.rank_illu {
  padding: 0px 0px;
  padding-bottom: 120px;
  max-width: 1320px;
  margin: 0 auto;
}

.illu_view {
  width: 330px;
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.illu_view > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.illu_view_sub {
  font-size: 14px;
  font-family: "Min Sans-Regular";
  color: #050409;
  letter-spacing: -0.3px;
  margin-top: 4px;
}

.illu_view_count {
  margin: 0px 2px;
}

.illu_sell_count {
  margin: 0px 2px;
}

.illu_price {
  float: right;
  font-size: 20px;
  color: #ff562c;
  font-family: "Min Sans-bold";
}

/**실시간**/

.main_more {
  width: 100%;
  margin-top: 32px;
  margin-left: 8px;
  border: none;
  border-radius: 20px;
  height: 70px;
  background-color: #22222a;
}

.main_more > a {
  color: #fff;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  letter-spacing: -0.3px;
}

/**footer**/
.footer {
  padding: 44px 0px;
  max-width: 1320px;
  margin: 0 auto;
}

.footer_img {
  height: 20px;
  width: auto;
  margin-bottom: 20px;
}

.footer_img > img {
  width: 100%;
}

.footer_text_1 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.footer_text_2 {
  font-family: "Min Sans-Regular";
  font-size: 13px;
  color: #050409;
  letter-spacing: -0.3px;
  opacity: 0.5;
}

.footer_text_3 {
  margin-top: 20px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  font-size: 13px;
}

.footer_text_3 > a {
  color: #050409;
  text-decoration: none;
  margin-right: 12px;
}
@media (max-width: 1400px) {
  .custom_navbar .custom_navbar_nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 14px;
    letter-spacing: -0.5px;
    margin: 0px 10px;
  }

  .rank_synop {
    max-width: 1296px;
  }

  .rank_illu {
    max-width: 1296px;
  }

  .footer {
    max-width: 1296px;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .novel_view {
    width: 208px;
  }
  .synop_illu {
    height: 208px;
  }
  .synop_overlay {
    top: 94px;
    left: 10px;
  }
}

@media (max-width: 1100px) {
  .novel_view {
    width: 208px;
    height: 370px;
  }
  .synop_illu {
    height: 208px;
  }
  .synop_overlay {
    top: 94px;
    left: 10px;
  }
  .illu_view {
    width: 260px;
  }
}
@media (min-width: 768px) and (max-width: 780px) {
  .custom_navbar_nav li a {
    font-size: 12px;
  }
}
@media (min-width: 100px) and (max-width: 780px) {

  .carousel_item {
    width : inherit;
    height : fit-content;
  }

  .rank_title {
    padding-top: 60px;
  }

  .rank_veteran {
    margin-bottom: 40px;
  }

  .rank_free {
    padding: 0px 0px 40px;
  }
}

@media (max-width: 780px) {
  /**nav**/
  .custom_navbar {
    background-color: #fff;
    height: 50px;
    padding: 0 18px;
    margin: 0 auto;
    margin-bottom: 12px;
  }

  .nav_logo {
    height: 18px;
  }

  .navbar_brand {
    margin-left: 0px;
  }

  .navbar_toggler {
    padding: 4px 0px;
  }

  .navbar > .container {
    padding: 0px;
  }

  .custom_navbar .custom_navbar_nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 12px 18px;
  }

  .navbar_collapse {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    top: 60px;
    right: 20px;
  }

  .custom_navbar .custom_navbar_nav li a:before {
    left: 0px;
    right: 0px;
  }

  /**slide**/

  .rank_synop {
    max-width: 540px;
  }

  .rank_illu {
    max-width: 540px;
  }

  .footer {
    max-width: 540px;
  }

  .rank_title {
    padding-top: 120px;
  }

  .container_l {
    max-width: 540px;
  }

  .container_d {
    max-width: 540px;
  }
}
@media (min-width: 100px) and (max-width: 780px) {

  .carousel_item {
    width : inherit;
    height : fit-content;
  }
  
  .rank_title {
    padding-top: 60px;
  }
  
  .rank_veteran {
    margin-bottom: 40px;
  }
  
  .rank_free {
    padding: 0px 0px 40px;
  }
  .synop_illu {
    width: 220%;
    height: auto;
  }  
  .synop_title {
    width: 220%;
  }
    
  .synop-overlay {
    inset: 70px 0px 0px 10px;
  }
    
  .illu-view {
    width: 100%;
    height: 360px;
  }
    
}