@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }

/**/

.leftAside .top{
    display: flex;
    gap: 12px;
}

.leftAside .content{
    padding: 54px 40px 0 40px;
}


.profile{
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
    
}

.topProfile{
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
}

.bottomProfile{
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
}

.coin, .block{
    display: flex;
    justify-content: space-between;
    letter-spacing: -0.03em;
}

.bottomProfile .right{
    text-align: right;
    text-decoration: underline;
    letter-spacing: -0.03em;
}

.my .tit{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    letter-spacing: -0.03em;
}
.my .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color:#8F8F99;
    margin-left: 4px;
    letter-spacing: -0.03em;
}

.list_group{
    font-family: "Min Sans-Regular";
}
.list:first-of-type{
    border-top: 1px solid #2C2C34;
}
.list{
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2C2C34;
    padding: 12px 0 12px 20px ;

}

.list:last-of-type{
    margin-bottom: 42px;
}

.list_group .down{
    margin-left: auto;

}

.list_group .cate{
    padding-right: 4px;
}

.my.edit .list{
    color: #8F8F99;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
}


.rightAside .content{
    padding: 120px 40px 40px 40px;
}

#wrap {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: auto; 
    height: 100vh;
}

.leftAside, .container, .rightAside {
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
}

.container {
    background-color: #fff;
}

.rightAside .title{
    color: #8F8F99;
    font-family: "Min Sans-Light";
    font-size: 17px;
    letter-spacing: -0.3px;
    line-height: 1.5;
    
}
.userAction ul{
    margin-top: 20px;
}
.userAction .name{
    font-size: 15px;
    color: #fff;
    letter-spacing: -0.03em;
}

.userAction li {
    padding:17px 20px;
    border: 1px solid #464651;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 12px;
    letter-spacing: -0.03em;
}

#container{
    padding: 0 140px;
}


#container .top{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 10px;
    letter-spacing: -0.03em;
    padding-top: 54px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
}

#container .top .left{
    display: flex;
    letter-spacing: -0.3px;
    gap: 4px;
}
#container .top .right{
    display: flex;
    font-size: 15px;
    font-family: "Min Sans-Medium";
    gap: 10px;
    color: #828284;
    align-items: center;
    letter-spacing: -0.3px;
}


.saveBtn{
    background-color: #fff;
    color: #828284;
    border: 1px solid #BDBDBD;
    border-radius: 20px;
    padding: 8px 21px;
    font-family: "Min Sans-Medium";
    cursor: pointer;
    letter-spacing: -0.3px;
}

#container .content {
    padding-top: 24px;
    padding-bottom: 40px;
}
#container .content p{
    font-family: "Min Sans-Light";
    color:#22222A;
    letter-spacing: -0.03em;
    line-height: 2;
    font-size: 18px;
}

#container{
    overflow-y: auto;
    
}


.hidden-list {
    display: none;
    margin-top: 6px;
}


.hidden-list li{
    padding: 6px 0;
    color: #8F8F99;
    letter-spacing: -0.3px;
}

.hidden-list li:hover{
    color: #fff;
}

.list-group .list{
    cursor: pointer;
    letter-spacing: -0.3px;
}



.list-group .list:hover {
    color: #FF5F37;
  }

.saveBtn:hover{
    background-color: #22222A;
    color: #fff;
    border: 1px solid #22222A;
    transition: ease-in 0.15s;
}
@media(max-width:1400px){
    .leftAside{
        display: none;
    }

    #wrap{
        display: grid;
        grid-template-columns: 3fr 1.5fr ;
        grid-template-rows: auto; 
        height: 100vh;
    }
}

@media(min-width:100px) and (max-width:768px){
    .leftAside,
    .rightAside{
        display: none;
    }

    #wrap{
        display: block;
    }
    #container{
        padding: 0 22px;
    }

    .synop-tab{
        margin-top: 70px;
    }
    #container h2{
        font-size: 24px;
        padding-bottom: 17px;
        font-family: "min sans-bold";
    }

    .content{
        padding-top: 42px;
    }

    .top{
        flex-direction: column;
    }

    .right{
        margin-left: auto;
    }

    
}