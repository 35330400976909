*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
}

body{
  overflow-x: hidden;
  -webkit-user-select: none;
  user-select: none;
}

li{
  list-style: none;
}

a{
  text-decoration: none;
  color: inherit;
  border-top: none;
}

h1, h2, h3, h4, h5{
  margin: 0;
  padding: 0;
  font-family: normal;
}

hr{
  color: #d9d9d9;
  margin-top: 60px;
}

img {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.custom_class_to_image {
  width: 100%;
  height: auto;
  object-fit: cover;
}


.synop_select_tab_nav li a.active {
    background: #040A20;
    color: #fff;
    border: 0px solid #040A20;
  }
.synop_select_tab_nav_sort li a.active {
    background: #040A20;
    color: #fff;
    border: 1px solid #040A20;
  }

  #synop01.active + .new {
    display: block;
  }
  #synop02 .notice li:first-of-type{
    border-top: 1px solid #ededed;
  }
  .custom_navbar .navbar_toggler:active,
.custom_navbar .navbar_toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.custom_navbar .custom_navbar-nav li.active a {
  opacity: 1;
}
.custom_navbar .custom_navbar-nav li.active a:before {
  width: calc(100% - 16px);
}

input[id="checkBox1"]:checked + label em,
input[id="checkBox2"]:checked + label em{
  background-position: -38px 0;
}

.my_picture_tab li a.active {
  background: white;
  color: #050409;
}
::-webkit-scrollbar {
  display: none;
}

