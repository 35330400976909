@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}


/*body*/

/**nav**/

.nav-logo {
    height: 24px;
  }
  
  .custom-navbar {
    background: #fff !important;
    height: 120px;
  }
  
  .navbar-brand {
    margin-left: 16px;
  }
  
  .custom-navbar .navbar-toggler {
    border-color: transparent;
  }
  .custom-navbar .navbar-toggler:active,
  .custom-navbar .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }
  
  .custom-navbar .custom-navbar-nav li a {
    color: #050409 !important;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    position: relative;
    font-family: "Min Sans-Medium";
    font-size: 17px;
    letter-spacing: -0.5px;
    margin: 0px 20px;
  }
  
  .custom-navbar .custom-navbar-nav li a:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 8px;
    right: 8px;
    background: #ff562c;
    height: 2px;
    opacity: 1;
    visibility: visible;
    width: 0;
    -webkit-transition: 0.15s all ease-out;
    -o-transition: 0.15s all ease-out;
    transition: 0.15s all ease-out;
  }
  
  .custom-navbar .custom-navbar-nav li a:hover {
    opacity: 1;
  }
  .custom-navbar .custom-navbar-nav li a:hover:before {
    width: calc(100% - 16px);
  }
  .custom-navbar .custom-navbar-nav li.active a {
    opacity: 1;
  }
  .custom-navbar .custom-navbar-nav li.active a:before {
    width: calc(100% - 16px);
  }
  .custom-navbar .custom-navbar-cta {
    margin-left: 0 !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .custom-navbar .custom-navbar-cta {
    margin-left: 20px !important;
  }
  
  .custom-navbar .custom-navbar-cta li {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .custom-navbar .custom-navbar-cta li:first-of-type {
    margin-right: 20px;
  }
  
  /**slide**/
  
  .carousel {
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .carousel-inner {
    width: 100%;
    display: flex;
    transition: transform 0.8s ease-in-out;
  }
  
  .carousel-inner .carousel-item {
    padding: 0px 10px;
  }
  
  .carousel-inner .carousel-item > img {
    border-radius: 20px;
  }
  
  .carousel-indicators {
    right: 10px;
  }
  
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .carousel-control-next,
  .carousel-control-prev {
    padding: 0px 0px;
    width: 80px;
  }

  /* cotent */

#joinContainer{
    height: 100vh;
    max-width: 524px;
    margin: auto;
    padding: 0 22px;
}

.joinBox{
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
}

.title{
  text-align: center;
  margin-bottom: 82px;  
}

.title h4{
    font-family: "Min Sans-bold";
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #050409;
    padding-bottom:8px;
}
.title h2{
    font-family: "Min Sans-bold";
    font-size: 46px;
    letter-spacing: -0.3px;
    color: #050409;
}
.userInput {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
}

.userInput input{
    outline: none;
    padding: 18px 20px;
    border: 1px solid #BDBDBD;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #050409;
}  

.userInput input::placeholder{
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #828284;
}


  /* 제목 스타일 */
  .label {
    color: #050409;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
  }
  
  /* 각 입력 필드와 중복검사 버튼 간격 설정 */
  .inputRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .inputRow:last-of-type{
    margin-bottom: 0;
  }

  .inputRow:nth-of-type(2){
    margin-bottom: 42px;
  }


  .overlap {
    display: flex;/* 이렇게 설정하면 아래 코드가 동작합니다. */

}

.overlap input[type="text"],
.overlap input[type="email"]{
    width: 80%;
}

.descript span{
    color: #FF5F37;
    font-family: "Min Sans-regular";
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-left: 6px;

}

  /* 중복검사 버튼 스타일 */
  .checkButton {
    margin-left: 16px;
    border: none;
    background-color: #ff562c;
    padding: 18px 22px;
    color: #fff;
    font-family: "Min Sans-regular";
    font-size: 16px;
    letter-spacing: -0.3px;

  }



.saveNextBtn{
  color: #fff;
  background-color: #22222A;
  border-radius: 20px;
  font-family: "Min Sans-Medium";
  font-size: 17px;
  margin-top: 52px;
  margin-bottom: 24px;
  width: 100%;
  padding: 24px;
  border: none;
  cursor: pointer;

}

.saveNextBtn:hover{
  background-color: #303039;
}
.checkButton:hover{
  background-color: #ff7957;
}

@media (max-width: 1400px) {
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 14px;
      letter-spacing: -0.5px;
      margin: 0px 10px;
    }
  
    .rank-synop {
      max-width: 1296px;
    }
  
    .rank-illu {
      max-width: 1296px;
    }
  
    .footer {
      max-width: 1296px;
    }

    .joinBox{
        padding-top: 120px;
    }

    .saveNextBtn{
        margin-bottom: 120px;
    }

    ;
  }

  @media (max-width: 560px) {
    /**nav**/
    .custom-navbar {
      background-color: #fff;
      height: 50px;
      padding: 0 18px;
      margin: 0 auto;
      margin-bottom: 12px;
    }
  
    .nav-logo {
      height: 18px;
    }
  
    .navbar-brand {
      margin-left: 0px;
    }
  
    .navbar-toggler {
      padding: 4px 0px;
    }
  
    .navbar > .container {
      padding: 0px;
    }
  
    .custom-navbar .custom-navbar-nav li a {
      color: #050409 !important;
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease;
      position: relative;
      font-family: "Min Sans-Medium";
      font-size: 17px;
      letter-spacing: -0.5px;
      margin: 12px 18px;
    }
  
    .navbar-collapse {
      position: absolute;
      z-index: 99;
      background-color: #fff;
      top: 60px;
      right: 20px;
      width: 100%;
    }
  
    .nav-link {
      padding: 0px 22px;
    }
  
    .custom-navbar .custom-navbar-nav li a:before {
      margin: 10px 14px;
      bottom: -16px;
    }
  
    /**slide**/
  
    .rank-synop {
      max-width: 540px;
    }
  
    .rank-illu {
      max-width: 540px;
    }
  
    .footer {
      max-width: 540px;
    }
  
    .rank-title {
      padding-top: 120px;
    }
  
    .container-l {
      max-width: 540px;
    }
  
    .container-d {
      max-width: 540px;
    }

    .logBox{
        padding-top: 48px;
    }
  }

  @media(max-width:568px){
    .container{
      padding: 0 22px;
      
    }

    .joinBox{
      padding-top: 70px;
    }

    .title{
      margin-bottom: 62px;
    }
    .title h4{
      font-size: 18px;
    }
    .title h2{
      font-size: 36px;
    }

    hr{
      margin: 42px 0;
    }

    .inputRow{
      margin-bottom: 24px;
    }
    .inputRow:nth-of-type(2){
      margin-bottom: 0;
    }


    .userInput{
      margin: 0;
    }

    .saveNextBtn{
      margin-top: 42px;
      margin-bottom: 40px;
    }

    
  }