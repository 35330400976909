@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }


/**/

#container{
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
}

.infoNav{
    border-bottom: 1px solid #EDEDED;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
}
.topInfo{
    display: flex;
    height: 66px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin: 0 auto;
    padding: 0 320px;


}

.epiInfo{
    display: flex;
    font-family: "Min Sans-bold";
    color: #22222A;
}

.back i {
    scale: 1.2;
    cursor: pointer;
}

.epiNum{
    padding-right:18px;
    padding-left: 20px;
}
.userSelect{
    display: flex;
}

.userSelect button{
    font-size: 15px;
    font-family: "Min Sans-Medium";
    color: #22222a;
    width: 100px;
    height: 36px;
    background-color: #F6F6F6;
    border: none;
    border-radius: 21px;
    margin-left: 8px;
    letter-spacing: -0.02em;
    cursor: pointer;
}

.userSelect .recommand{
    background-color: #fff;
    border: 1px solid #FF5F37;
    color: #FF5F37;
}

.contentBox{
    margin-top: 66px;
    margin-bottom: 60px;

}

.contentTit{
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 32px;
    line-height: 2;

}

.contentTit p {
    font-family: "Min Sans-Regular";
    font-size: 18px;
    letter-spacing: -0.03m;
    color: #050409;
}



.content{
    font-family: "Min Sans-Regular";
    font-size: 18px;
    letter-spacing: -0.03m;
    line-height: 2;
    margin-top: 32px;
    color: #050409;
    max-width: 100%;

}

.list-group .list:hover {
    color: #FF5F37;
  }



@media(max-width: 1400px){
    #container{
        max-width:100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
    }

    .topInfo{
        padding: 0px 120px;
    }

    .userSelect .next{
        margin-right: 10px;
    }
    .userSelect .recommand {
        font-size: 0;
        border: none;
        background-image: url('images/ddabong.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 36px;
        height: 36px;
    }

    .userSelect .comment{
        font-size: 0;
        background-image: url('images/message.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 36px;
        height: 36px;
        margin-left: 12px;
    }

    .epiInfo{
        min-width: 100px;
        text-overflow: ellipsis;
    }
    .epiTitle{

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }
}
@media (min-width: 1401px) {
    .comment {
        display: none;
    }
}


@media(max-width: 568px) {
    #container{
        padding: 0 22px;
    }

    .topInfo{
        padding:0 18px;
        height: 50px;
    }
    .epiInfo{
        font-size: 15px;
    }

    .epiNum{
        padding: 0 12px;
    }

    .userSelect{
        display: none;
    }
    .contentTit{
        font-size: 17px;
        line-height: 2.4;
    }

    .content{
        font-size: 17px;
        line-height: 2.4;
    }

    /**container**/

    .contentBox{
        margin-top: 90px;
    }
    
}


