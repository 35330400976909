.modal {
    display: none; /* 기본 상태는 보이지 않음 */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
    text-align: center;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/*내 글 리스트 선택*/
.relistcontainor {
    display: flex;
}

.relistmylist {
    width: 47%;
    padding: 32px;
    border: 1px solid #dbdbdb;
    margin: 32px 0px 32px 32px;
}

.relistaddarrow {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #989898;
}

.relistaddlist {
    width: 47%;
    padding: 32px;
    border: 1px solid #dbdbdb;
    margin: 32px 32px 32px 0px;
}

.relist {
    display: flex;
    color: #1a1a1a;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    margin-bottom: 32px;
}

.relist:first-of-type {
    border-top: 1px solid #2c2c34;
}

.relist:last-of-type {
    margin-bottom: 42px;
}

.recate {
    margin-right: 4px;
}

#downArrow1 {
    margin-left: auto;
}

#bookList1 > li {
    text-align: left;
}

#bookList1 > li:hover {
    color: #1a1a1a;
}

.relist_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.relist_item > span {
    font-size: 16px;
    color: #1a1a1a;
    font-family: "Min Sans-Regular";
}

.relistcheck {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.relistmylist .tit {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.relistmylist .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color: #8f8f99;
    margin-left: 4px;
}

.relistaddlist .tit {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.relistaddlist .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color: #8f8f99;
    margin-left: 4px;
}

.addhidden_list {
    display: block;
    margin-top: 6px;
}

.addhidden_list li {
    padding: 6px 0px;
}
  /* 파일 업로드 */
.insert {
    padding: 20px 30px;
    display: block;
    width: 600px;
    margin: 5vh auto;
    height: 50vh;
    border: 1px solid #dbdbdb;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.insert .file_list {
    height: 318px;
    overflow: auto;
    border: 1px solid #989898;
    padding: 10px;
    text-align: left;
    list-style-type: none;
  }
.insert .file_list .filebox p {
    font-size: 14px;
    margin-top: 10px;
    display: inline-block;
  }
.insert .file_list .filebox .delete i {
    color: #ff5353;
    margin-left: 5px;
  }

.insert .file_item {
    margin: 5px 0;
    font-family: "Min Sans-Regular";
}

.delete_btn {
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 16px;
}

.deletebtn:hover {
    color: darkred;
}

.reuploadform {
    border: none;
    width: 100%;
    margin-top: 10px;
    display: contents;
}

.upload_btn_wrapper {
    text-align: left;
}

.custom_file_upload {
    font-family: "Min Sans-Medium";
    font-size: 15px;
    color: #fff;
    background-color: #ff562c;
    padding: 14px 80px;
    display: inline-block;
    margin-bottom: 20px;
    border-radius: 4px;
}

.reuploadbtn {
    padding: 14px 110px;
    display: inline;
    margin-bottom: 20px;
    border: none;
    background-color: #ff562c;
    color: #fff;
    border-radius: 4px;
    font-family: "Min Sans-Medium";
    font-size: 15px;
}

.reuploadbtn:hover {
    cursor: pointer;
}

.reuploadtitle {
    font-family: "Min Sans-bold";
    font-size: 24px;
    margin-bottom: 24px;
}

.reuploadinfo {
    font-family: "Min Sans-regular";
    font-size: 16px;
    color: #828284;
}