@font-face {
  font-family: 'Min Sans-bold';
  src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Medium';
  src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Regular';
  src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
}
@font-face {
  font-family: 'Min Sans-Light';
  src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
}

/**/

.logo {
  height: 24px;
}

.logo > img {
  height: 100%;
}

.leftAside .top {
  display: flex;
  gap: 12px;
}

.leftAside .content {
  padding: 54px 40px 0 40px;
}

.profile {
  border: 1px solid #464651;
  border-radius: 12px;
  padding: 20px;
  margin-top: 46px;
  margin-bottom: 42px;
}

.topProfile {
  color: #fff;
  display: flex;
  font-size: 18px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  padding-bottom: 24px;
  gap: 4px;
}

.bottomProfile {
  color: #ff5f37;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.03em;
  gap: 8px;
}

.coin,
.block {
  display: flex;
  justify-content: space-between;
}

.bottomProfile .right {
  text-align: right;
  text-decoration: underline;
}

.my .tit {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.my .tit p {
  font-size: 22px;
  font-family: "Min Sans-bold";
  letter-spacing: -0.03em;
  color: #8f8f99;
  margin-left: 4px;
}

.list_group {
  font-family: "Min Sans-Regular";
}
.list:first-of-type {
  border-top: 1px solid #2c2c34;
}
.list {
  display: flex;
  color: #fff;
  border-bottom: 1px solid #2c2c34;
  padding: 12px 0 12px 20px;
}

.list:last-of-type {
  margin-bottom: 42px;
}

.list_group .down {
  margin-left: auto;
}

.list_group .cate {
  padding-right: 4px;
}

.my.edit .list {
  color: #8f8f99;
  font-family: "Min Sans-Regular";
}

#wrap {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  height: 100vh;
}

.leftAside,
.container,
.rightAside {
  background-color: #22222a;
  height: 100%;
  overflow-y: auto;
}

/**container**/

.container {
  background-color: #fff;
}

#container {
  overflow: scroll;
  padding: 0 140px;
}
/**edit-4**/

.edit_start {
  width: 100%;
  background-color: #ff5f37;
  color: #fff;
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  margin: 50px 0px 16px 0px;
  height: 70px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
}

.edit_start:hover {
  background-color: #ff7957;
}

.edit_save {
  width: 100%;
  background-color: #fff;
  color: #ff5f37;
  font-size: 17px;
  font-family: "Min Sans-Medium";
  letter-spacing: -0.3px;
  margin: 0px 0px 120px 0px;
  height: 70px;
  border-radius: 20px;
  outline: none;
  border: 1px solid #ff5f37;
  cursor: pointer;
}

.edit_save:hover {
  border: 2px solid #ff5f37;
}

.d_flex {
  display: flex;
  align-items: flex-end;
}

.novel_info_cate {
  padding: 6px 15px;
  color: #fff;
  background-color: #22222a;
  font-family: "Min Sans-Medium";
  font-size: 13px;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
  border-radius: 30px;
  width: -moz-fit-content;
  width: fit-content;
}

.main_title {
  font-family: "Min Sans-bold";
  font-size: 20px;
  color: #050409;
  letter-spacing: -0.3px;
  margin-right: 6px;
}

.novel_info_title {
  margin-bottom: 8px;
}

.novel_info_intro {
  font-size: 15px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  color: #22222a;
  line-height: 24px;
}

.novel_box_img > img {
  height: 240px;
}

.novel_box {
  height: auto;
  margin: 10px 0px 0px 0px;
  padding: 32px 32px;
  border: 1px solid #bdbdbd;
  border-radius: 20px;
}

.novel_box_info {
  width: 600px;
  margin-left: 20px;
}
/**edit-3**/

/**edit-2**/


.sub_title {
  font-family: "Min Sans-Regular";
  font-size: 14px;
  color: #828284;
  align-items: baseline;
}

/**edit-1**/
.synop_tab_group {
  width: 100%;
}

.synop_tab {
  margin-top: 108px;
  display: flex;
  justify-content: center;
}

.synop_tab_btn > a {
  padding: 12px 96px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #a8a8a9;
  text-align: center;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_tab_btn_line {
  width: 100%;
  height: 4px;
  background-color: #ff5f37;
  margin-top: 12px;
}

.synop_tab_btn_u {
  text-align: center;
}

.synop_tab_btn_u > a {
  padding: 12px 0px;
  font-family: "Min Sans-Medium";
  font-size: 16px;
  color: #ff5f37;
  position: relative;
  letter-spacing: -0.3px;
}

.synop_select {
  margin-top: 76px;
}

.synop_select_title {
  font-family: "Min Sans-bold";
  font-size: 32px;
  letter-spacing: -0.3px;
}

.content_box{
  display: flex;
  align-items: flex-end;
}


/**modal**/
.swal2_title {
  font-size: 24px !important;
  font-family: "Min Sans-bold";
  color: #050409 !important;
}

.swal2_html_container {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Min Sans-Regular";
  color: #828284;
}

.swal2_popup {
  padding: 2.25rem !important;
}

.swal2_confirm {
  font-size: 16px;
  font-family: "Min Sans-Regular";
  color: #828284;
}

.swal2_cancel {
  font-size: 16px;
  font-family: "Min Sans-Regular";
  color: #828284;
}
.swal2_footer {
  font-family: "Min Sans-Regular";
}

@media (max-width: 1400px) {
  #wrap {
    display: grid;
    grid-template-columns: 1.5fr 4fr;
    grid-template-rows: auto;
    height: 100vh;
  }
}
@media(min-width:769px){
  .author,
  .series{
    display: none;
  }
}
@media (min-width: 100px) and (max-width: 768px) {
  .leftAside,
  .rightAside {
    display: none;
  }

  #wrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  #container {
    padding: 0 22px;
  }
  .synop_select{
    margin-top: 60px;
  }
  .synop_tab{
    margin-top: 70px;
  }

  .rank_synop{
    padding-bottom: 80px;
  }

  .synop_select_title{
    font-size: 24px;
  }

  .synop_select_sub{
    font-size: 16px;
  }

  .novel_box{
    padding: 18px;
    border-color: #EDEDED;
  }

  .novel_info_cate{
    display: none;
  }
  .content_box{
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .novel_box_info{
    margin: 0;
    width: auto;
  }

  .author,
  .series{
    display: block;
  }
  .novel_info_title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  .novel_info_intro{
    line-height: 26px;
    word-break: normal;
  }
  .subTit{
    display: flex;
    gap: 8px;
    margin-top: 6px;

  }

  .main_title{
    font-size: 22px;
  }

  .author{
    font-size: 13px;
    font-family: "min sans-regular";
    letter-spacing: -.3px;
    color: #050409;
  }

  .series{
    font-size: 13px;
    font-family: "min sans-regular";
    letter-spacing: -.3px;
    color: #828284;
  }
  .edit_start{
    margin-top: 32px;
  }
  .edit_save{
    margin-bottom: 80px;
    border: 1px solid #ff5f37;
  }

}

