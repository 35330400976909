@font-face {
    font-family: 'Min Sans-bold';
    src: url('../css/font/MinSans-Bold_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Medium';
    src: url('../css/font/MinSans-Medium_otf.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Regular';
    src: url('../css/font/MinSans-Regular_oft.otf') format('opentype');
  }
  @font-face {
    font-family: 'Min Sans-Light';
    src: url('../css/font/MinSans-Light_otf.otf') format('opentype');
  }

/**/

.leftAside .top{
    display: flex;
    gap: 12px;
}

.leftAside .content{
    padding: 54px 40px 0 40px;
}


.profile{
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
}

.topProfile{
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
}

.bottomProfile{
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
}

.coin, .block{
    display: flex;
    justify-content: space-between;
}

.bottomProfile .right{
    text-align: right;
    text-decoration: underline;
}

.my .tit{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.my .tit p {
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color:#8F8F99;
    margin-left: 4px;
}

.list_group{
    font-family: "Min Sans-Regular";
}
.list:first-of-type{
    border-top: 1px solid #2C2C34;
}
.list{
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2C2C34;
    padding: 12px 0 12px 20px ;

}

.list:last-of-type{
    margin-bottom: 42px;
}

.list_group .down{
    margin-left: auto;

}

.list_group .cate{
    padding-right: 4px;
}

.my.edit .list{
    color: #8F8F99;
    font-family: "Min Sans-Regular";
}


.rightAside .content{
    padding: 120px 40px 40px 40px;
}



.leftAside, .container, .rightAside {
    background-color: #22222A;
    height: 100%;
    overflow-y: auto; 
}

.container {
    background-color: #fff;
}

.rightAside .title{
    color: #8F8F99;
    font-family: "Min Sans-Light";
    font-size: 17px;
    letter-spacing: -0.3px;
    line-height: 1.5;
}
.userAction ul{
    margin-top: 20px;
}
.userAction .name{
    font-size: 15px;
    color: #fff;
    letter-spacing: -0.03em;
}

.userAction li {
    padding:17px 20px;
    border: 1px solid #464651;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 12px;
}



.list_group .list:hover {
    color: #FF5F37;
  }

@media(max-width:1400px){
    .leftAside{
        display: none;
    }



    .rightAside{
        min-width: 320px;
    }

}

@media(min-width:100px) and (max-width:768px){
    .leftAside, .rightAside{
        display: none;
    }



}

.hidden_list {
    display: none;
    margin-top: 6px;
}


.hidden_list li{
    padding: 6px 0;
    color: #8F8F99;
}

.hidden_list li:hover{
    color: #fff;
}

.list_group .list{
    cursor: pointer;
}


